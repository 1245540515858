/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../Header/component';
import AppImg from '../../assets/images/fa-app-img.png';
import RedCross from '../../assets/Img/red-cross.png';
import Succes from '../../assets/Img/success.png';
import './styles.scss';

import {
  addNewAccount,
  authenticateMetaTraderAc,
  getNumberOftrade
} from './accountApi';
import { selectAccounts, selectUserDetails } from 'features/login/loginSlice';
import { stripeCheckout } from './accountApi';
import { getUserDetails } from 'features/profile-screen/userApi';
import { setUserdetails, updateAccounts } from '../login/loginSlice';


const NewAccount = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector(selectUserDetails);
  const [negativeTradeModel, setNegativeTradeModel] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('monthly');
  const [accountIdChange, setAccountIdChange] = useState('');
  const [loginValue, setLoginValue] = useState(
    location?.state?.loginValue ? location?.state?.loginValue : ''
  );
  const [videLinkModel, setVideLinkModal] = useState(false);
  const [broker, setBroker] = useState(
    () => localStorage.getItem('broker') || 'selectBroker'
  );
  const [errorMessage, setErrorMessage] = useState(false);
  const [isMtConfigired, setIsMtConfigired] = useState(false);
  const faAccounts = useSelector(selectAccounts);
  const [disableButton, setDisableButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const [linkClicked, setLinkClicked] = useState(
    localStorage.getItem('linkClicked') === 'true'
  );

  const [step, setstep] = useState(1);
  const accountId = new URLSearchParams(location.search).get('account_id');
  const DefaultId = accountId === null ? data?.user.account_ids[0] : accountId;
  const Helper = require('../../utils/helper');
  const token = localStorage.getItem('jwtToken');

  const fetchUser = async () => {
    try {
      const data = await getUserDetails();
      dispatch(setUserdetails(data));
      dispatch(updateAccounts(data?.accounts || []));
    } catch (error) { }
  };

  useEffect(() => {
    Helper.isTokenExpired(token);
  }, []);

  useEffect(() => {
    if (data?.user === undefined) {
      fetchUser();
    }
  }, []);

  useEffect(() => {
    if (broker !== 'selectBroker' && loginValue !== '' && !loading) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
    localStorage.setItem('loginValue', loginValue);
    localStorage.setItem('broker', broker);
  }, [broker, loginValue, loading]);

  const validateMetaTraderAC = async () => {
    try {
      const res = await authenticateMetaTraderAc(loginValue);
      if (res?.success) {
        setIsMtConfigired(true);
        setstep(3);
      }
    } catch (error) {
      setErrorMessage(error);
      setIsMtConfigired(false);
      setstep(3);
    }
  };

  const downloadEA = () => {
    const metaTraderUrl =
      broker === 'mt5'
        ? 'https://fractalalpha.s3.us-east-2.amazonaws.com/FA-EA-MT5.mq5'
        : 'https://drive.google.com/uc?id=1qvAwOKEw1vu1GkYVN3O09QXy_DvkXKXc';
    window.open(metaTraderUrl, '_blank');
  };

  const handleBrokerChange = (e) => {
    setBroker(e.target.value);
  };

  const handleLoginChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, '');
    setLoginValue(numericValue === '' ? '' : parseInt(numericValue));
    setErrorMessage('');
  };

  const addMtAccount = async () => {
    try {
      setLoading(true);
      setErrorMessage('');
      if (broker === 'selectBroker') {
        setErrorMessage('Broker is required.');
        setLoading(false);
        return;
      }
      if (!loginValue) {
        setErrorMessage('Login is required');
        setLoading(false);
        return;
      }

      const res = await addNewAccount(
        {
          login: loginValue,

          broker
        },
        accountId
      );

      if (res?.success) {
        setstep(2);
        localStorage.setItem('setpage', 2);
      }
    } catch (error) {
      setErrorMessage(
        error ? error : 'An error occurred while processing the request.'
      );
    } finally {
      setLoading(false);
    }
  };

  const makePayment = async () => {
    setLoading(true);
    try {
      const res = await getNumberOftrade(loginValue);
      if (res.success) {
        setLoading(false);
        setPaymentModal(true);
      }
    } catch (error) {
      if (error.negative_value) {
        setNegativeTradeModel(true);
        setLoading(false);
      } else {
        setLoading(false);
        setstep(1);
        console.error('Error ----->:', error);
        navigate(`/analysis-failed/account_id=${accountId}`);
      }
    }
  };

  const handleClicked = () => {
    if (linkClicked) {
      validateMetaTraderAC();
      setErrorMessage('');
    } else {
      setErrorMessage('Please download and install the FA app first');
    }
  };

  useEffect(() => {
    if (localStorage.getItem('linkClicked') === 'true') {
      setLinkClicked(true);
    }
  }, []);

  function handleKeyPress(event) {
    if (event.key === '13') {
      addMtAccount();
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    addMtAccount();
  };

  const handleChange = (event) => {
    setAccountIdChange(event.target.value);
  };
  const handleRadioChange = (event) => {
    setSelectedPlan(event.target.value);
  };

  const toggle = () => setPaymentModal(false);
  const negativeModel = () => setNegativeTradeModel(false);
  const negativeModelBackButton = () => {
    setNegativeTradeModel(false);
    setstep(1);
  };

  const _subscription = async () => {
    setPaymentModal(false);
    try {
      const plan = selectedPlan.toLowerCase();
      const resubscribe = false
      const customer_id = data?.user?.stripe_customer_id;
      await stripeCheckout(plan, accountId, customer_id, resubscribe);
    } catch (error) {
      console.error('Error initiating analysis:', error);
      throw error;
    }
  };

  return (
    <>
      <div className="d-flex">
        {/* <div className="left_container"> */}
        <div className="main--container step-page-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12 col-lg-12 p-0">
                <Header />
                <div className="table-container">
                  <div className="table-sub-container">
                    <div className="account-container">
                      <div className="d-flex align-items-center justify-content-between">
                        <select className="form-select" value={DefaultId}>
                          <option
                            value={DefaultId}
                            key={DefaultId}
                            selected={DefaultId}
                          >
                            Account #{DefaultId}
                          </option>
                        </select>
                        {/* <div>
                          {step === 2 && (
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                setVideLinkModal(true);
                              }}
                            >
                              SETUP VIDEO INSTRUCTIONS
                            </button>
                          )}
                          {step === 3 && (
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                setVideLinkModal(true);
                              }}
                            >
                              SETUP VIDEO INSTRUCTIONS
                            </button>
                          )}
                        </div> */}
                      </div>
                    </div>
                    {/* <div className="fa-filter-block">
                        {faAccounts.filter(
                          (account) => account.status === 'pending'
                        ).length > 1 && (
                          <div className="fa-filter">
                            <div>
                              <select
                                className="form-select"
                                value={accountId}
                                onChange={handleChange}
                              >
                                {faAccounts
                                  .filter(
                                    (account) => account.status === 'pending'
                                  )
                                  .map((account) => (
                                    <option
                                      key={account.account_id}
                                      value={account.account_id}
                                    >
                                      Account #{account.account_id}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        )}
                      </div> */}
                    <div className="analysis-block">
                      <div className="analysis-header">
                        <h1 className="analysis-heading">
                          Initiate Analysis in
                          <br />
                          <span className="green">3 Simple Steps</span>
                        </h1>
                        <div className="body-paragraph">
                          <h6>
                            Follow these three steps to initiate the
                            FractalAlpha Analysis of your past trading
                            performace.
                          </h6>
                        </div>
                      </div>
                      <div className="analysis-step-block">
                        <div className="analysis-steps">
                          <div className="analysis-step-list">
                            <div className="step-title">STEP</div>
                            <div
                              className={
                                step == 1
                                  ? 'step-circle stepcount'
                                  : 'step-circle'
                              }
                            >
                              <span className="step-no">1</span>
                            </div>
                            <div className="step-footer">
                              <h3>Account Integration</h3>
                              <p>
                                Provide your MetaTrader login credentials
                                which will establish a secure connection
                                between your MT platform account and
                                FractalAlpha.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="analysis-steps">
                          <div className="analysis-step-list">
                            <div className="step-title">STEP</div>

                            <div
                              className={
                                step == 2
                                  ? 'step-circle stepcount'
                                  : 'step-circle  '
                              }
                            >
                              <span className="step-no">2</span>
                            </div>
                            <div className="step-footer">
                              <h3>Download the FA App</h3>
                              <p>
                                Download and install the FractalAlpha App into
                                your MetaTrader platform's "Data folder" to
                                securely exchange past trading data.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="analysis-steps">
                          <div className="analysis-step-list">
                            <div className="step-title">STEP</div>
                            <div
                              className={
                                step == 3
                                  ? 'step-circle stepcount last-step'
                                  : 'step-circle last-step'
                              }
                            >
                              <span className="step-no">3</span>
                            </div>
                            <div className="step-footer">
                              <h3>Analysis</h3>
                              <p>
                                Let our advanced algorithms analyze your
                                trading history, providing you with a tailored
                                trading plan that maximizes your potential.
                              </p>
                            </div>
                          </div>

                        </div>

                      </div>
                      <div className="video-instruction-container">
                        {step === 2 && (
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              setVideLinkModal(true);
                            }}
                          >
                            SETUP VIDEO INSTRUCTIONS
                          </button>
                        )}
                        {step === 3 && (
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              setVideLinkModal(true);
                            }}
                          >
                            SETUP VIDEO INSTRUCTIONS
                          </button>
                        )}
                      </div>

                      {step == 1 && (
                        <div className="analysis-footer">
                          <form onSubmit={handleSubmit}>
                            <div className="pb-2">
                              <select
                                className="form-select"
                                value={broker}
                                onChange={handleBrokerChange}
                                required
                              >
                                <option
                                  value="selectBroker"
                                  disabled={broker !== 'selectBroker'}
                                >
                                  Select a broker
                                </option>
                                <option value="mt5">Meta Trader 5</option>
                                {/* <option value="mt4">Meta Trader 4</option> */}
                              </select>
                            </div>
                            <div className="pb-2">
                              <input
                                type="text"
                                placeholder="Login"
                                className="form-control"
                                value={loginValue}
                                onChange={handleLoginChange}
                                required
                              />
                            </div>

                            {errorMessage && (
                              <div className="text-center text-danger mt-2">
                                {errorMessage}
                              </div>
                            )}
                            <div className="text-center pt-2">
                              <button
                                type="button"
                                className="btn btn-secondary btn-sm "
                                onClick={addMtAccount}
                                onKeyDown={handleKeyPress}
                                disabled={disableButton || loading}
                              >
                                {loading ? 'Please wait...' : 'Authenticate'}
                              </button>
                            </div>
                          </form>
                        </div>
                      )}

                      {step == 2 && !errorMessage && (
                        <div className="analysis-footer ">
                          <h6>
                            Download and Install the FA app on your Platform
                          </h6>
                          <div className="fa-app-platform">
                            <img alt='AppImg' src={AppImg} />
                          </div>
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setLinkClicked(true);
                              localStorage.setItem('linkClicked', 'true');
                              downloadEA();
                            }}
                          >
                            FractalAlpha EA
                          </a>
                          <p>Version 1.0</p>
                          {errorMessage && (
                            <div className="text-center text-danger mt-2">
                              {errorMessage}
                            </div>
                          )}
                          <div className="button-container text-center mt-3">
                            <button
                              type="button"
                              className="btn btn-outline-dark me-3 btn-sm btn-md"
                              onClick={() => {
                                setErrorMessage('');

                                setstep(1);
                                localStorage.setItem('setpage', 1);
                              }}
                            >
                              Back
                            </button>

                            <button
                              className="btn btn-outline-dark btn-sm btn-md"
                              onClick={handleClicked}
                              disabled={!linkClicked}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      )}
                      {step == 3 && !isMtConfigired && errorMessage && (
                        <div className="analysis-footer">
                          <div className="unsuccessfull-cross">
                            <img alt="RedCross" src={RedCross} />
                          </div>
                          <h6 className="red mb-2">
                            CONNECTION UNSUCCESSFUL
                          </h6>
                          <p>
                            We couldn't establish a connection between your MetaTrader platform and Fractal Alpha. <br />
                            Please check your MetaTrader credentials, ensure the FA EA app is installed correctly, or verify that you have closed trades. Then, try again.
                          </p>
                          <div style={{ fontWeight: 'bold', padding: '10px', textAlign: 'center' }}>
                            OR
                          </div>
                          <div style={{ color: 'red' }}>{errorMessage}</div>
                          <div className="mt-4">
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm btn-md"
                              onClick={() => {
                                setErrorMessage('');
                                setstep(1);
                                localStorage.setItem('setpage', 1)
                              }}
                            >
                              Back
                            </button>
                          </div>
                        </div>
                      )}
                      {isMtConfigired && step == 3 && (
                        <div className="analysis-footer">
                          <div className="unsuccessfull-cross">
                            <img
                              alt="Succes"
                              src={Succes}
                              style={{ maxWidth: '50px', height: '50px' }}
                            />
                          </div>
                          <h6 className="green mb-2">SUCCESS</h6>
                          <p>
                            MetaTrader and FractaAlpha Connection has
                            successfully been established!
                          </p>
                          <div className="mt-4">
                            <button
                              className="btn btn-primary btn-sm btn-xll"
                              onClick={makePayment}
                            >
                              {loading ? 'Please wait....' : 'Choose Plan!'}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <button
                className="chat--icon"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
              >
                <img alt="chatIcon" src={chatIcon} />
              </button>
              <div
                class="offcanvas offcanvas-end"
                tabindex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
              >
                <div class="offcanvas-header">
                  <button
                    type="button"
                    class="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="offcanvas-body">...</div>
              </div> */}
          </div>
        </div>
        {/* </div> */}
        <div className="right_contaier"></div>
      </div>
      <Modal
        isOpen={paymentModal}
        toggle={toggle}
        centered
        className="new-account-modal"
      >
        <ModalHeader toggle={toggle}>New Account</ModalHeader>
        <ModalBody>
          <h1>
            <span className="rate">
              {selectedPlan === 'monthly' ? '$49' : '$509'}
            </span>
            <span className="monthly">
              {selectedPlan === 'monthly' ? '.95 monthly' : '.49 yearly'}
            </span>
          </h1>
          <div className="monthly-yearly-toggle-block">
            <div class="pricing-switcher">
              <div className="fieldset">
                <input
                  type="radio"
                  name="group-a"
                  value="monthly"
                  id="monthly-1"
                  checked={selectedPlan === 'monthly'}
                  onChange={handleRadioChange}
                />
                <label htmlFor="monthly-1">Monthly</label>
                <input
                  type="radio"
                  name="group-a"
                  value="yearly"
                  id="yearly-1"
                  checked={selectedPlan === 'yearly'}
                  onChange={handleRadioChange}
                />
                <label htmlFor="yearly-1">Yearly</label>
                <span className="switch"></span>
              </div>
            </div>
            <span className="yellowText">Save 15%</span>
          </div>
          <ul className="plans-list">
            <li>Ai Trading Analysis</li>
            <li>Personalized Trading Plan</li>
            <li>Real-time AI Chat</li>
            <li>Live Consistency Score</li>
            <li>Multi-Account Management</li>
            <li>Dedicated Trade Analysis</li>
            <li>Journal and Insights</li>
            <li>Secure Data Handling</li>
          </ul>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button
            className="btn-sm w-25"
            color="primary"
            onClick={_subscription}
          >
            Next
          </Button>
          <span className="footer-title">
            Cancel your subscription at any time without any hassle.
          </span>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={negativeTradeModel}
        toggle={negativeModel}
        centered
        className="new-account-modal"
      >
        <ModalHeader toggle={negativeModel}>Strategy Update</ModalHeader>
        <ModalBody>
          <p style={{ textAlign: 'center' }}>
            It looks like your trading strategy has resulted in more negative
            trades than positive ones. To enhance your profitability, please
            refine your approach, focus on trading with more favorable values,
            and try again.
          </p>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button
            className="btn-sm w-25"
            color="primary"
            onClick={negativeModelBackButton}
          >
            Back
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={videLinkModel}
        toggle={() => {
          setVideLinkModal(!videLinkModel);
        }}
        className="new-account-modal"
        size="md"
        centered
      >
        <ModalHeader>
          How to Link Fractal & MetaTrader
          {/* <img
            alt="CrossIcon"
            src={CrossIcon}
            style={{ padding: '5px', alignSelf: 'flex-end', cursor: 'pointer' }}
            onClick={() => {
              setVideLinkModal(!videLinkModel);
            }}
          /> */}
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              margin: '10px',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <p style={{ textAlign: 'center', paddingBottom: '20px' }}>
              Click the link below to watch a YouTube tutorial on linking your
              Fractal account with MetaTrader for seamless integration.
            </p>
            <button
              className="btn btn-sm btn-success w-40"
              onClick={() => {
                setVideLinkModal(false);
                window.open(
                  'https://www.youtube.com/watch?v=-Z7QSW3R7h8',
                  '_blank'
                );
              }}
            >
              Watch Now
            </button>
            {/* <a href="https://www.youtube.com/watch?v=D0Lr91-p6Pg">
              https://www.youtube.com/watch?v=D0Lr91-p6Pg
            </a> */}
          </div>
          {/* <img src={YoutubeLink} alt="youtubeimg" width="50%" height="50%" /> */}
        </ModalBody>
      </Modal>
    </>
  );
};

export default NewAccount;
