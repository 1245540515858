/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useRef, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FractaLogo from '../../assets/images/fractal-logo-icon.svg';
import {
  setSelectedAccount,
  addUserMessage,
  sendChatMessageThunk,
  setCurrentAccount,
  setCurrentLogin
} from './ChatSlice';
import {
  selectUserName,
  setDefaultAccount,
  getDefaultAccount
} from '../../features/login/loginSlice';
import '../../features/fa-account/styles.scss';
import { message } from 'antd';
import { clearChatMessage } from './ChatSlice';
import { debounce, isEqual } from 'lodash';
import { getTradingPlan } from './tradingPlanSlice';
import { capitalizeFirstLetter } from '../../utils/helper';

var tempTradingPlan = {};

const ChatbotWeb = (props) => {
  const { from, activeAccounts } = props;
  const dispatch = useDispatch();
  const {
    chatMessages,
    selectedAccount,
    selectedLogin,
    currentAccount,
    currentLogin
  } = useSelector((state) => state.chatbot);
  const userName = useSelector(selectUserName);
  const defaultAccount = useSelector(getDefaultAccount);

  const [chatMessage, setChatMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const key = from === 'analysis' ? 'analysis' : '';

  const textareaRef = useRef(null);
  const messagesEndRef = useRef(null);

  const firstLetterOfUsername = userName
    ? userName.charAt(0).toUpperCase()
    : '';

  const uniqueMessages = [];
  const messageSet = new Set();

  chatMessages.forEach((item) => {
    if (item.type === 'alert' || item.key === 'trade') {
      if (!messageSet.has(item.text)) {
        messageSet.add(item.text);
        uniqueMessages.push(item);
      }
    } else {
      uniqueMessages.push(item);
    }
  });

  const { tradingPlan, newtradingPlan } = useSelector(
    (state) => state.tradingPlan
  );

  useEffect(() => {
    if (activeAccounts) {
      if (
        (from === 'account' && selectedLogin === '') ||
        activeAccounts.length < 0
      ) {
        setIsDisabled(true);
      } else if (activeAccounts.length > 0) {
        setIsDisabled(false);
      }
    }
  }, [selectedLogin, from]);

  useEffect(() => {
    if (!isEqual(tempTradingPlan, newtradingPlan)) {
      tempTradingPlan = newtradingPlan;
      try {
        if (currentLogin !== '' || currentAccount !== '') {
          if (
            newtradingPlan?.daily_profit_target >
            tradingPlan?.daily_profit_target
          ) {
            const chatMessage = `Congrats! You have reached the daily profit target of $${Math.abs(
              tradingPlan.daily_profit_target ?? 0
            ).toLocaleString()}. Great job!`;
            dispatch(
              sendChatMessageThunk({
                account_id: currentAccount,
                login: currentLogin,
                message: chatMessage,
                name: userName,
                type: 'alert',
                key: key
              })
            );
          }
          if (
            newtradingPlan?.profit_target_per_trade >
            tradingPlan?.profit_target_per_trade
          ) {
            const chatMessage = `You've reached your profit target for this trade of $${Math.abs(
              tradingPlan.profit_target_per_trade ?? 0
            ).toLocaleString()}. Well done! Exiting a trade when it hits your target ensures you capture gains and prevents you from giving back profits.Consistency in achieving these targets contributes to long-term profitability.`;
            dispatch(
              sendChatMessageThunk({
                account_id: currentAccount,
                login: currentLogin,
                message: chatMessage,
                name: userName,
                type: 'alert',
                key: key
              })
            );
          }
          if (
            newtradingPlan?.maximum_daily_loss > tradingPlan?.maximum_daily_loss
          ) {
            const chatMessage = `You have violated your maximum daily loss target of $${Math.abs(
              tradingPlan.maximum_daily_loss ?? 0
            ).toLocaleString()}. It's important to honor this limit to protect your capital and maintain a healthy trading mindset. Remember, losses are part of trading, but managing them ensures you'll stay in the game longer and avoid a devastating blow to your account.`;
            dispatch(
              sendChatMessageThunk({
                account_id: currentAccount,
                login: currentLogin,
                message: chatMessage,
                name: userName,
                type: 'alert',
                key: key
              })
            );
          }
          if (newtradingPlan?.stop_loss < tradingPlan?.stop_loss) {
            const chatMessage = `Your stop loss has been triggered at $${Math.abs(
              tradingPlan.stop_loss ?? 0
            ).toLocaleString()}. While it might feel frustrating, setting and respecting stop losses is crucial for capital preservation. It helps you manage risk effectively by limiting losses on any given trade and keeping emotions in check.`;
            dispatch(
              sendChatMessageThunk({
                account_id: currentAccount,
                login: currentLogin,
                message: chatMessage,
                name: userName,
                type: 'alert',
                key: key
              })
            );
          }
          if (
            newtradingPlan?.maximum_trades_per_day >
            tradingPlan?.maximum_trades_per_day
          ) {
            const chatMessage = `You have reached the maximum number of trades allowed for today. Limiting the number of trades helps you avoid overtrading and maintain discipline. Every trade should be carefully considered rather than driven by impulse. Stick to the plan and avoid unnecessary risks.`;
            dispatch(
              sendChatMessageThunk({
                account_id: currentAccount,
                login: currentLogin,
                message: chatMessage,
                name: userName,
                type: 'alert',
                key: key
              })
            );
          }
          if (
            newtradingPlan?.maximum_position_size >
            tradingPlan?.maximum_position_size
          ) {
            const chatMessage = `You are about to exceed your maximum position size. Remember, keeping position sizes within limits prevents overexposure to risk. Managing position size is key to maintaining a balanced and sustainable trading approach.`;
            dispatch(
              sendChatMessageThunk({
                account_id: currentAccount,
                login: currentLogin,
                message: chatMessage,
                name: userName,
                type: 'alert',
                key: key
              })
            );
          }
          if (newtradingPlan?.avoid_time_of_day === true) {
            const chatMessage = `You are approaching a time you've decided to avoid trading. Sticking to this rule can help you avoid volatile or less predictable market conditions that don't align with your strategy. Sometimes, the best trade is the one you don’t take.`;
            dispatch(
              sendChatMessageThunk({
                account_id: currentAccount,
                login: currentLogin,
                message: chatMessage,
                name: userName,
                type: 'alert',
                key: key
              })
            );
          }
          if (newtradingPlan?.avoid_day_of_week === true) {
            const chatMessage = `It's ${tradingPlan.avoid_day_of_week}, which is set as a day to avoid trading. Remember, avoiding trading on certain days can help you stay clear of historically less favorable market conditions or times when your strategy is less effective. Use this time to analyze past performance or simply take a break.`;
            dispatch(
              sendChatMessageThunk({
                account_id: currentAccount,
                login: currentLogin,
                message: chatMessage,
                name: userName,
                type: 'alert',
                key: key
              })
            );
          }
        }
      } catch (error) {
        console.log('alert message error', error);
      }
    }
  }, [newtradingPlan]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = 'auto'; // Reset the height
    textarea.style.height = textarea.scrollHeight + 'px'; // Set to the scroll height
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [chatMessage]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom(); // Auto-scroll to the bottom when chatMessages change
  }, [isLoading]);

  const handleSendMessage = debounce(async () => {
    if (chatMessage.trim() !== '') {
      dispatch(
        addUserMessage({
          text: chatMessage,
          type: 'user',
          timestamp: new Date().toLocaleString('en-US', {
            month: 'short',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
          })
        })
      );
      setChatMessage(''); // Clear input field after sending message
      setIsLoading(true);
      try {
        await dispatch(
          sendChatMessageThunk({
            account_id: currentAccount,
            login: currentLogin,
            message: chatMessage,
            name: userName,
            type: 'bot',
            key: key
          })
        ).unwrap();
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        message.error('Failed to send message', 1);
      }
    }
  }, 250);

  const handleSelectChange = (event) => {
    const accountId = event.target.value;
    dispatch(setCurrentAccount(accountId));
    dispatch(getTradingPlan(accountId));
    setIsDisabled(false);
    const selectedAccount =
      activeAccounts &&
      activeAccounts.find((account) => account.account_id === accountId);
    dispatch(clearChatMessage(true));
    if (selectedAccount) {
      dispatch(
        setSelectedAccount({
          accountId: selectedAccount.account_id,
          login: selectedAccount.login
        })
      );
      dispatch(setCurrentLogin(selectedAccount.login));
      dispatch(setDefaultAccount(selectedAccount.account_id));
    }
  };

  React.useEffect(() => {
    if (activeAccounts?.length === 1 && !selectedAccount) {
      const singleAccount = activeAccounts[0];
      dispatch(
        setSelectedAccount({
          accountId: singleAccount.account_id,
          login: singleAccount.login
        })
      );
    } else if (activeAccounts?.length > 1 && defaultAccount !== null) {
      setIsDisabled(false);
      const selectedAccount =
        activeAccounts &&
        activeAccounts.find((account) => account.account_id === defaultAccount);
      if (selectedAccount) {
        dispatch(
          setSelectedAccount({
            accountId: selectedAccount.account_id,
            login: selectedAccount.login
          })
        );
      }
    }
  }, [activeAccounts, selectedAccount, dispatch, defaultAccount]);

  return (
    <div className="chatboat-section">
      <div className="chat-header">
        <div>
          {activeAccounts && activeAccounts.length > 0 && (
            <div className="chat-footer-dropdown" style={{ padding: 0 }}>
              <div className="d-flex align-items-center justify-content-between gap-3">
                <div>
                  {activeAccounts.length === 1 ? (
                    <div style={{ color: 'white' }}>
                      Account #{activeAccounts[0].account_id}
                    </div>
                  ) : (
                    <>
                      {from === 'account' && (
                        <select
                          className="form-select"
                          value={selectedAccount}
                          onChange={handleSelectChange}
                        >
                          <option value="">
                            Please select fractal account
                          </option>
                          {activeAccounts.map((account) => (
                            <option
                              key={account.account_id}
                              value={account.account_id}
                            >
                              Account #{account.account_id}
                            </option>
                          ))}
                        </select>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <button
          type="button"
          className="btn-close btn-close-white mobile-close-btn text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>

      <div className="chatboat-chat-section">
        <div className={'chat-block'}>
          <div className="chat-preview-container">
            <div
              className="chat-preview chat-preview-flex"
              style={{ marginBottom: '20' }}
            >
              <div className="default-chatting">
                {isLoading && (
                  <>
                    <div className="chat-icon-skeleton">
                      <img alt="FractaLogo" src={FractaLogo} />
                    </div>
                    <div className="dotsContainer">
                      <span id="dot1"></span>
                      <span id="dot2"></span>
                      <span id="dot3"></span>
                    </div>
                  </>
                )}
              </div>
              <div className="message-container">
                {uniqueMessages.map((msg, index) => {
                  // if (
                  //   from === 'account' &&
                  //   selectedAccount === '' &&
                  //   defaultAccount === null
                  // ) {
                  //   return (
                  //     <div
                  //       key={`${index}`}
                  //       className="multiple-message-container"
                  //     >
                  //       <div className="outer-message-container">
                  //         <div className="chat-icon-skeleton">
                  //           <img alt="FractaLogo" src={FractaLogo} />
                  //         </div>
                  //         <div
                  //           className="bot-message-container"
                  //           style={{ whiteSpace: 'pre-line' }}
                  //         >
                  //           <div className="bot-message">{msg.text}</div>
                  //           <div
                  //             className="time-div icon-adjust"
                  //             style={{ display: 'flex' }}
                  //           >
                  //             {msg.timestamp}
                  //           </div>
                  //         </div>
                  //       </div>
                  //     </div>
                  //   );
                  // }
                  if (msg.type === 'bot') {
                    let maxLoss = Math.abs(
                      tradingPlan.maximum_daily_loss ?? 0
                    ).toLocaleString();
                    let dailyProfitTarget = Math.abs(
                      tradingPlan.daily_profit_target ?? 0
                    ).toLocaleString();

                    let message = `Hey ${capitalizeFirstLetter(
                      userName
                    )}! Today, let’s keep a sharp eye on managing risks and make sure we stay \nwithin our daily max loss of $${maxLoss}. Let’s also aim for a daily profit target of $${dailyProfitTarget}. Stay \ndisciplined, stay focused, and happy trading!`;
                    let isTradingPlan = tradingPlan?.daily_profit_target;
                    const isWelcomeMsg = msg.text.includes(
                      'Welcome to Fractal Alpha!'
                    );

                    let welcomeMsg =
                      currentAccount && isTradingPlan && isWelcomeMsg
                        ? message
                        : msg.text;

                    welcomeMsg =
                      from === 'account' && selectedAccount === ''
                        ? msg.text
                        : welcomeMsg;

                    return (
                      <div
                        key={`${index}`}
                        className="multiple-message-container"
                      >
                        <div className="outer-message-container">
                          <div className="chat-icon-skeleton">
                            <img alt="FractaLogo" src={FractaLogo} />
                          </div>
                          <div
                            className="bot-message-container"
                            style={{ whiteSpace: 'pre-line' }}
                          >
                            <div className="bot-message">{welcomeMsg}</div>
                            <div
                              className="time-div icon-adjust"
                              style={{ display: 'flex' }}
                            >
                              {msg.timestamp}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  } else if (msg.type === 'user') {
                    return (
                      <div
                        key={`${index}`}
                        className="outer-user-message-container"
                      >
                        <div className="chat-icon-skeleton last-item">
                          <div className="chat-icon-skeleton-text">
                            {firstLetterOfUsername}
                          </div>
                        </div>
                        <div className="user-message-container">
                          <div className="user-message">{msg.text}</div>
                          <div
                            className="time-div icon-adjust"
                            style={{ display: 'flex' }}
                          >
                            {msg.timestamp}
                          </div>
                        </div>
                      </div>
                    );
                  } else if (msg.type === 'alert') {
                    return (
                      <div
                        key={`${index}`}
                        className="outer-user-message-container"
                      >
                        <div className="outer-message-container">
                          <div className="chat-icon-skeleton">
                            <img alt="FractaLogo" src={FractaLogo} />
                          </div>
                          <div
                            className="alert-message-container"
                            style={{ whiteSpace: 'pre-line' }}
                          >
                            <div className="bot-message">
                              <strong>Alert:</strong> {msg.text}
                            </div>
                            <div
                              className="time-div icon-adjust"
                              style={{ display: 'flex' }}
                            >
                              {msg.timestamp}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
                <div ref={messagesEndRef} />{' '}
                {/* This element is used for auto-scrolling */}
              </div>
            </div>
          </div>
        </div>
        <div className="chat-footer">
          <textarea
            placeholder={
              (selectedAccount && selectedAccount.length) || from === 'analysis'
                ? 'Send a message'
                : 'Please select fractal account'
            }
            className="chat-input"
            maxLength="225"
            value={chatMessage}
            onChange={(e) => setChatMessage(e.target.value)}
            style={{
              width: '90%',
              resize: 'none',
              overflowY: chatMessage.split('\n').length > 3 ? 'auto' : 'hidden', // Add scroll if more than 3 lines
              maxHeight: '4.5rem' // Maximum height for 3 lines
            }}
            readOnly={isDisabled}
            ref={textareaRef}
            rows="1"
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault(); // Prevent the default new line behavior
                handleSendMessage(); // Send the message
              } else if (e.key === 'Enter' && e.shiftKey) {
                e.preventDefault(); // Prevent the default behavior
                const cursorPosition = textareaRef.current.selectionStart; // Get the current cursor position
                const textBeforeCursor = chatMessage.substring(
                  0,
                  cursorPosition
                ); // Text before cursor
                const textAfterCursor = chatMessage.substring(cursorPosition); // Text after cursor
                // Insert a single newline at the cursor position
                setChatMessage(textBeforeCursor + '\n' + textAfterCursor);
                // Adjust the cursor position after inserting the newline
                setTimeout(() => {
                  textareaRef.current.selectionStart = cursorPosition + 1;
                  textareaRef.current.selectionEnd = cursorPosition + 1;
                }, 0);
              }
            }}
          />
          <img
            className="chat-disabled-send-button"
            title="Send message"
            alt="send button"
            src={
              chatMessage && chatMessage.trim().length
                ? require('../../assets/images/send-icon.png')
                : require('../../assets/images/send_disable_icon.png')
            }
            style={{ display: 'block', width: 20, height: 20 }}
            onClick={handleSendMessage}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(ChatbotWeb);
