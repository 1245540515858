import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { sendOtp, signIn } from './loginApi';
import { getUserDetails } from './loginApi';
type TAccount = { account_id: string; created_at: string; status: string };
interface Trade {
  _id: string;
  closingTime: string;
  currentPrice: number;
  currentProfit: number;
  graph_data: any[];
  insights_details: object;
  login: number;
  openPrice: number;
  symbol: string;
  tradeTime: string;
  type: string;
  volume: number;
}

// Define the initial state

type UserState = {
  userName: string;
  email: string;
  userId: string;
  selectedAccount: string;
  accounts: TAccount[];
  loading: boolean;
  userloading: boolean;
  jwt: string | undefined;
  data: any | null;
  error: string | undefined;
  userDetails: {};
  paymentHistory: {};
  newAccountButtonDisabled: boolean;
  twoFA: boolean;
  analysisData: {};
  tradesData: Trade[];
  upcomingReportTrade: string;
  totalTrades: 0;
  journaldata: [];
  analysisGraphData: null;
  defaultAccount: string | null;
};

const initialState: UserState = {
  userName: '',
  email: '',
  userId: '',
  accounts: [],
  loading: false,
  data: null,
  selectedAccount: '',
  error: '',
  jwt: '',
  userDetails: {},
  userloading: false,
  newAccountButtonDisabled: false,
  twoFA: false,
  paymentHistory: {},
  analysisData: {},
  upcomingReportTrade: '',
  tradesData: [],
  totalTrades: 0,
  journaldata: [],
  analysisGraphData: null,
  defaultAccount: null
};
function moveIdToTop(trade: Trade): Trade {
  const { _id, ...rest } = trade;
  return { _id, ...rest };
}
const loginSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    updateUserName: (state, action: PayloadAction<string>) => {
      state.userName = action.payload;
    },
    setUserdetails: (state, action) => {
      state.email = action.payload.user.email;
      state.userDetails = action.payload;
      state.userName = action.payload.user.first_name;
    },
    updateAccounts: (state, action: PayloadAction<TAccount[]>) => {
      state.accounts = action.payload;
    },
    addUserEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    setSelectedAccount: (state, action: PayloadAction<string>) => {
      state.selectedAccount = action.payload;
    },
    UpdateUserDetails: (state, action: PayloadAction<string>) => {
      state.userDetails = action.payload;
    },
    setnewAccountButtonDisabled: (state, action: PayloadAction<boolean>) => {
      state.newAccountButtonDisabled = action.payload;
    },
    updateTwoFA: (state, action) => {
      state.twoFA = action.payload;
    },
    setPaymentHistory: (state, action) => {
      state.paymentHistory = action.payload;
    },
    setanalysisData: (state, action: PayloadAction<string>) => {
      state.analysisData = action.payload;
    },
    analysisGraph: (state, action) => {
      state.analysisGraphData = action.payload;
    },
    setTradesData: (state, action: PayloadAction<[]>) => {
      state.tradesData = action.payload;
    },
    addTrade: (state, action: PayloadAction<Trade>) => {
      state.tradesData.unshift(moveIdToTop(action.payload));
    },
    setTradesTotal: (state, action) => {
      state.totalTrades = action.payload;
    },
    incrementTradesTotal: (state) => {
      state.totalTrades += 1;
    },
    setUpcomingReportTrade: (state, action) => {
      state.upcomingReportTrade = action?.payload;
    },
    addAccounts: (state, action) => {
      state.accounts.unshift(action.payload);
    },
    setjournaldata: (state, action) => {
      state.journaldata = action.payload;
    },
    setDefaultAccount: (state, action) => {
      state.defaultAccount = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, action) => {
      const response = action.payload.data;
      state.userDetails = response;
      state.accounts = action.payload.accounts;
      state.userName = response.user.first_name;
      state.email = response.user.email;
      state.userId = response.user._id;
      state.data = response;
      state.jwt = response.token;
      state.twoFA = response.user.twoFA;
      state.loading = false;
      state.error = '';
    });
    builder.addCase(sendOtp.fulfilled, (state, action) => {
      const response = action?.payload?.data;
      state.userDetails = response;
      state.accounts = action.payload.accounts;
      state.userName = response?.user?.first_name;
      state.email = response?.user?.email;
      state.userId = response?.user?._id;
      state.data = response;
      state.jwt = response?.token;
      state.twoFA = response?.user?.twoFA;
      state.loading = false;
      state.error = '';
    });
    builder.addCase(signIn.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });
    builder.addCase(getUserDetails.pending, (state) => {
      state.userloading = true;
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      const response = action?.payload;
      state.email = response?.user?.email;
      state.userName = response?.user?.first_name;
      state.twoFA = response?.user?.twoFA;
      state.userloading = false;
      state.accounts = action?.payload?.accounts;
      state.userDetails = action?.payload;
    });
    builder.addCase(getUserDetails.rejected, (state, action) => {
      state.userloading = false;
      state.error = 'An error occurred';
    });
  }
});
export const {
  updateUserName,
  updateAccounts,
  addUserEmail,
  setLoading,
  setUserId,
  setSelectedAccount,
  UpdateUserDetails,
  setUserdetails,
  setnewAccountButtonDisabled,
  updateTwoFA,
  setPaymentHistory,
  setanalysisData,
  analysisGraph,
  setUpcomingReportTrade,
  addAccounts,
  incrementTradesTotal,
  setTradesData,
  setTradesTotal,
  setjournaldata,
  addTrade,
  setDefaultAccount
} = loginSlice.actions;
export const selectAccounts = (state) => state.login.accounts;
export const selectEmail = (state) => state.login.email;
export const selectUserName = (state) => state.login.userName;
export const selectLoading = (state) => state.login.loading;
export const getSelectedAccount = (state) => state.login.selectedAccount;
export const getUserId = (state) => state.login.userId;
export const getAllAccountIds = (state) => state.login.allAccountIds;
export const selectUserDetails = (state) => state.login.userDetails;
export const Userloading = (state) => state.login.userloading;
export const getnewAccountButtonDisabled = (state) =>
  state.login.newAccountButtonDisabled;
export const getTwoFa = (state) => state.login.twoFA;
export const getPaymentHistory = (state) => state.login.paymentHistory;
export const getAnalysisData = (state) => state.login.analysisData;
export const getAnalysisGraphData = (state) => state.login.analysisGraphData;
export const getTradesData = (state) => state.login.tradesData;
export const getjournalData = (state) => state.login.journaldata;
export const getTradesTotal = (state) => state.login.totalTrades;
export const getUpcomingReportTrade = (state) =>
  state.login.upcomingReportTrade;
export const getDefaultAccount = (state) => state.login.defaultAccount;
export default loginSlice.reducer;
