/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Header from '../Header/component';
import CrossIcon from '../../assets/images/cross-icon.svg';
import './styles.scss';
import {
  Emotion,
  Infiniti,
  SadAngry,
  Sadface,
  Smile,
  Smile2
} from 'assets/svgIcons';
import { useSelector } from 'app/store';
import SubHeader from '../../features/sub-header/component';
// import TradeTable from 'components/trades/tradeTable';
import TradeTable from './overviewTable';
import { Line } from 'react-chartjs-2';
import { TradeSide } from 'models/enums/tradeSide';
import { getFormattedDateTime } from 'utils/date';
import { forceUnaryOperator, formatPrice } from 'utils/numbers';
import { useParams } from 'react-router-dom';
import ChatbotMobileTradePlan from 'components/chatbot/chatbotMobileTradePlan';
import {
  // setTradesData,
  setUserdetails,
  updateAccounts,
  setDefaultAccount
  // setTradesTotal
} from 'features/login/loginSlice';
import { addTradeInsight } from './homeApi';
import {
  selectAccounts,
  addTrade,
  incrementTradesTotal
} from '../login/loginSlice';
import { getUserDetails } from 'features/profile-screen/userApi';
import { useDispatch } from 'react-redux';
import Loader from 'components/loader/loader';
import ChatbotWebTradePlan from '../../components/chatbot/ChatbotWebTradePlan';
import { getTradingPlan } from 'components/chatbot/tradingPlanSlice';
import { getCalculations, getGraphData } from './homeApi';
import { addUserMessage } from '../../components/chatbot/ChatSlice';
import { io } from 'socket.io-client';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Chart } from 'chart.js';
import { setUpdatedTrades } from './homeSlice';

const URL = 'wss://api.fractalalpha.com/deals';

const defaultLabel = [
  '',
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20
];

const HomeComponent = (props) => {
  const tradeQueue = useRef([]);
  const [toggleValue, setToggleValue] = useState('No');
  const [reflections, setReflections] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const allAccounts = useSelector(selectAccounts);
  const accounts = allAccounts?.filter(
    (account) => account.status === 'active'
  );
  const dispatch = useDispatch();
  const calculation = useSelector((state) => state.home.calculation);
  const graphData = useSelector((state) => state.home.graph_data);
  const trades = useSelector((state) => state.home.trades);
  const profitTarget = useSelector(
    (state) => state.tradingPlan.tradingPlan?.daily_profit_target
  );
  const lossTarget = useSelector(
    (state) => state.tradingPlan.tradingPlan?.maximum_daily_loss
  );
  const Helper = require('../../utils/helper');
  const token = localStorage.getItem('jwtToken');
  // const calculation = useSelector((state) => state.home.calculation);
  // const graphData = useSelector((state) => state.home.graph_data);
  const allTradesLoader = useSelector((state) => state.home.loader);

  let { accountId } = useParams();
  const [day, setDay] = useState('1095');
  const [selectedEmojis, setSelectedEmojis] = useState('');
  // const [tradeData, setTradeData] = useState(null);
  const [overallProfit, setOverallProfit] = useState(0);
  const [overallLoss, setOverallLoss] = useState(0);
  const [insightModal, setInsightModal] = useState({ show: false });
  // const [allTrades, setAllTrades] = useState(calculation?.all_trades);
  // const [profit_metrics, setpProfits] = useState(calculation?.profit_metrics);//
  // const [loss_metrics, setLosses] = useState(calculation?.loss_metrics);
  // const [headings, setHeadings] = useState(calculation?.headings);
  const [totaltradeCount, setTotaltradeCount] = useState(0);
  const [loader2, setLoader2] = useState(false);

  const [reflectionMessage, setReflectionMessage] = useState('');
  const firstAccountId = accounts.length > 0 ? accounts[0].account_id : null;
  const [wordCount, setWordCount] = useState(0);
  const [chartData, setChartData] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState({
    symbol: 'AAPL',
    dateRange: '2024-01-01 to 2024-12-31',
    volume: '100'
  });
  const [selectedAccount, setSelectedAccount] = useState(
    accountId !== undefined
      ? accountId
      : accounts.length > 0
      ? accounts[0].account_id
      : ''
  );
  const [selectedLogin, setSelectedLogin] = useState('');
  const [isYtd, setIsYtd] = useState(false);
  const [tradeLables, setTradeLables] = useState(defaultLabel);
  const [numberOfTrade, setNumberOfTrade] = useState(20);
  const [tradeChart2, setTradeChart2] = useState({
    labels: [],
    datasets: [
      {
        label: '',
        data: [], // Y-axis data (Profit & Loss values)
        fill: true,
        borderColor: 'rgba(75, 192, 192, 1)', // Line color
        backgroundColor: 'rgba(75, 192, 192, 0.2)', // Fill color
        borderWidth: 3,
        pointRadius: 0,
        tension: 0.2 // Curve tension
      }
    ]
  });
  const [tradeQueueCurrent, setTradeQueueCurrent] = useState([]);

  Chart.register(annotationPlugin);

  useEffect(() => {
    Helper.isTokenExpired(token);
    setReflectionMessage('');
  }, []);

  useEffect(() => {
    if (selectedAccount) {
      dispatch(getTradingPlan(selectedAccount));
    }
  }, [selectedAccount]);

  useEffect(() => {
    const selected_account = accounts.find(
      (account) => account.account_id === selectedAccount
    );
    if (selected_account) {
      setSelectedLogin(selected_account.login);
    }
  }, [accountId, accounts, selectedAccount]);

  useEffect(() => {
    if (accountId !== undefined) {
      setSelectedAccount(accountId);
    } else if (firstAccountId) {
      setSelectedAccount(firstAccountId);
    }
  }, [accountId, firstAccountId]);

  useEffect(() => {
    if (accounts.length === 0) {
      fetchUser();
    }
  }, []);

  useEffect(() => {
    const socket = io(URL, {
      query: { login: selectedLogin },
      reconnectionAttempts: 5, // Attempt to reconnect up to 5 times
      reconnectionDelay: 2000 // Delay between reconnection attempts
    });

    socket.on('connect', () => {
      console.log('WebSocket connected');
    });

    socket.on('disconnect', () => {
      console.log('WebSocket disconnected');
    });

    socket.on('error', (error) => {
      console.error('WebSocket error:', error);
    });

    socket.on('new_trade', (trade) => {
      try {
        if (trade) {
          console.log('WebSocket calling...');
          tradeQueue.current.push(trade);
          setTradeQueueCurrent([...tradeQueue.current]);
          dispatch(addTrade(trade));
          dispatch(incrementTradesTotal());
          dispatch(getTradingPlan(selectedAccount));
          dispatch(setUpdatedTrades(trade));
          fetchGraphData();
        }
      } catch (err) {
        console.error('Error in WebSocket handler:', err);
      }
    });

    return () => {
      socket.close();
      console.log('WebSocket connection closed');
    };
  }, [selectedLogin]);

  useEffect(() => {
    if (tradeQueue.current && tradeQueue.current.length > 0) {
      tradeQueue.current.forEach((data) => {
        if (data.currentProfit && data.currentProfit > 0) {
          const profitMessage = `🎉 Congratulations! You just closed a profitable trade on ${data.symbol} you earned $${data.currentProfit} with a volume of ${data.volume} lots. Keep up the good work! 💰`;
          dispatch(addUserMessage(Helper.getProfitMessage(profitMessage)));
        }
        if (data.currentProfit && data.currentProfit < 0) {
          const lossMessage = `⚠️ Trade Closed. Unfortunately, your trade on ${data.symbol} resulted in a loss of $${data.currentProfit} Don't worry, there's always another opportunity ahead!`;
          dispatch(addUserMessage(Helper.getLossMessage(lossMessage)));
        }
      });
    }
    tradeQueue.current = [];
  }, [tradeQueueCurrent]);

  const fetchUser = async () => {
    try {
      const data = await getUserDetails();
      dispatch(setUserdetails(data));
      dispatch(updateAccounts(data?.accounts || []));
    } catch (error) {}
  };

  const showInsight = (trade) => {
    if (Object.keys(trade?.insights_details)?.length !== 0) {
      setToggleValue(
        trade?.insights_details?.follow_trading_plan
          ? trade?.insights_details?.follow_trading_plan
          : 'No'
      );
      setReflections(
        trade?.insights_details?.reflection
          ? trade?.insights_details?.reflection
          : ''
      );
      setWordCount(trade?.insights_details?.reflection.length);
      setSelectedEmojis(
        trade?.insights_details?.emoji_reaction
          ? trade?.insights_details?.emoji_reaction
          : ''
      );
    }

    setInsightModal({ show: true, trade });
    setError('');
  };

  const onCloseInsightModal = () => {
    setWordCount(0);
    setToggleValue('No');
    setReflections('');
    setSelectedEmojis('');
    setInsightModal((prev) => ({ ...prev, show: false }));
    setError('');
    setReflectionMessage('');
  };

  const focusedTrade = insightModal.trade;

  // useEffect(() => {
  //   setAllTrades(calculation?.all_trades);
  //   setpProfits(calculation?.profit_metrics);
  //   setLosses(calculation?.loss_metrics);
  //   setHeadings(calculation?.headings);
  // }, [calculation]);

  // const fetchCalData = () => {
  //   try {
  //     dispatch(getCalculations({ selectedAccount, day }));
  //   } catch (error) {
  //     console.log('error===>>', error);
  //   }
  // };
  const fetchGraphData = () => {
    try {
      dispatch(getGraphData({ selectedAccount }));
    } catch (error) {
      console.log('error===>>', error);
    }
  };

  useEffect(() => {
    setIsLoading(true);

    const data = trades.map((trade) => trade.currentProfit).reverse();
    const updatedData = [0, ...data]; // Adding 0 as a starting value
    let result = [];
    updatedData &&
      updatedData.reduce((acc, curr) => {
        let data = acc + curr;
        result.push(Math.round(data));
        return data;
      }, 0);
    let updatedLabels = [];

    if (trades && trades.length > 20) {
      // Dynamically create labels starting from 21 onwards, based on the number of trades
      updatedLabels = Array.from({ length: updatedData.length }, (_, i) => i); // Labels from 1 to trades.length

      // if (numberOfTrade > 20) {
      //   // Add labels starting from 21 onwards, for example
      //   updatedLabels = Array.from(
      //     { length: numberOfTrade - 20 },
      //     (_, i) => 21 + i
      //   );
      // }
    } else {
      // Use default labels if trades are less than or equal to 20
      updatedLabels = defaultLabel;
    }

    // Set the labels and data to the chart state
    setTradeLables(updatedLabels);
    setTradeChart2((prevState) => ({
      ...prevState,
      labels: updatedLabels,
      datasets: [
        {
          ...prevState.datasets[0],
          data: result
        }
      ]
    }));

    setIsLoading(false);
  }, [trades, numberOfTrade]);

  // useEffect(() => {
  //   setIsLoading(true);
  //   if (trades && trades.length > 20) {
  //     const updatedLabels = [...tradeLables];
  //     for (let i = 21; i <= numberOfTrade; i++) {
  //       updatedLabels = Array.from({ length: trades.length }, (_, i) => i + 1);

  //     }
  //     const data = trades.map((trade) => trade.currentProfit).reverse();
  //     const updatedData = [0, ...data];
  //     setTradeLables(updatedLabels);
  //     setTradeChart2((prevState) => ({
  //       ...prevState,
  //       labels: updatedLabels,
  //       datasets: [
  //         {
  //           ...prevState.datasets[0],
  //           data: updatedData
  //         }
  //       ]
  //     }));
  //   }
  //   if (trades && trades.length < 20) {
  //     setTradeLables(defaultLabel);
  //     const data = trades.map((trade) => trade.currentProfit).reverse();
  //     const updatedData = [0, ...data];
  //     setTradeChart2((prevState) => ({
  //       ...prevState,
  //       labels: defaultLabel, // Update X-axis
  //       datasets: [
  //         {
  //           ...prevState.datasets[0],
  //           data: updatedData
  //         }
  //       ]
  //     }));
  //   }
  //   setIsLoading(false);
  // }, [trades]);

  const profitThreshold = graphData?.profits || 0;
  const lossThreshold = -(graphData?.losses || 0);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      annotation: {
        annotations: {
          profitLine: {
            type: 'line',
            yMin: profitThreshold,
            yMax: profitThreshold,
            borderColor: '#87CEEB', // Green color for profit
            borderWidth: 2
          },
          lossLine: {
            type: 'line',
            yMin: lossThreshold,
            yMax: lossThreshold,
            borderColor: 'red', // Red color for loss
            borderWidth: 2
          }
        }
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItems) {
            const label = tooltipItems[0].label; // X-axis label
            return `Trade: ${label}`;
          },
          label: function (tooltipItem) {
            const value = tooltipItem.raw; // Y-axis value
            if (value > 0) {
              return `Profit: $${value}`;
            } else {
              return `Loss: $${Math.abs(value)}`;
            }
          },
          labelColor: function (tooltipItem) {
            const value = tooltipItem.raw; // Y-axis value
            if (value > 0) {
              return {
                backgroundColor: '#4CAF50', // Green box for profit
                borderColor: '#4CAF50'
              };
            } else {
              return {
                backgroundColor: 'red', // Red box for loss
                borderColor: 'red'
              };
            }
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false // Removes vertical grid lines
        },
        beginAtZero: true
      },
      y: {
        beginAtZero: false // Ensure Y-axis starts at zero
      }
    }
  };

  useEffect(() => {
    // fetchCalData();
    fetchGraphData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount, day]);

  const handleEmojiClick = (emoji) => {
    setSelectedEmojis(emoji);
  };

  const handleReflections = (event) => {
    const inputText = event.target.value;

    if (inputText.length <= 250) {
      setWordCount(inputText.length);
      setReflections(inputText);
      setError('');
      setReflectionMessage('');
    } else {
      setError('');
      setReflectionMessage('You have reached the 250-character limit.');
    }
  };

  const handleToggleChange = () => {
    const newValue = toggleValue === 'No' ? 'Yes' : 'No';
    setToggleValue(newValue);
  };

  const saveTradeInsight = async () => {
    setIsLoading(true);
    setError('');
    if (reflections.trim() === '') {
      setError('Please enter reflections.');
      setIsLoading(false);
      return;
    }
    if (selectedEmojis === '') {
      setError('Please select emoji.');
      setIsLoading(false);
      return;
    }
    const data = {
      emoji_reaction: selectedEmojis,
      follow_trading_plan: toggleValue,
      reflection: reflections
    };
    await addTradeInsight(focusedTrade?._id, data);
    onCloseInsightModal();
    setIsLoading(false);
    setReflections('');
    setToggleValue('No');
    setSelectedEmojis('');
    dispatch(getGraphData({ selectedAccount }));
  };

  const handleSelectAccount = (account) => {
    dispatch(setDefaultAccount(account));
    setSelectedAccount(account);
  };

  const onRefresh = async (account) => {
    setLoader2(true);
    // await fetchData();
    // await fetchCalData();
    fetchGraphData();
    await dispatch(getTradingPlan(selectedAccount));
    setLoader2(false);
  };

  const valueChange = 12.5;
  const [violations, setViolations] = useState(new Set());
  const [hasTradeOccurred, setHasTradeOccurred] = useState(false);
  const [consistency, setConsistency] = useState(0);

  const { tradingPlan, newtradingPlan } = useSelector(
    (state) => state.tradingPlan
  );

  useEffect(() => {
    if (newtradingPlan?.maximum_trades_per_day === 0) {
      setConsistency(0);
      setHasTradeOccurred(false);
      setViolations(new Set());
      return;
    }

    if (!hasTradeOccurred && newtradingPlan?.maximum_trades_per_day > 0) {
      setConsistency(100);
      setHasTradeOccurred(true);
    }

    const checkCondition = (condition, violationKey, recoveryCondition) => {
      if (condition && !violations.has(violationKey)) {
        // Handle violation
        setViolations((prevViolations) => {
          const updatedViolations = new Set(prevViolations);
          updatedViolations.add(violationKey);
          return updatedViolations;
        });

        setConsistency((prev) => Math.max(0, prev - valueChange));
      } else if (
        !condition &&
        violations.has(violationKey) &&
        recoveryCondition
      ) {
        // Handle recovery
        setViolations((prevViolations) => {
          const updatedViolations = new Set(prevViolations);
          updatedViolations.delete(violationKey);
          return updatedViolations;
        });

        setConsistency((prev) => Math.min(100, prev + valueChange)); // Increase consistency
      }
    };

    // Check violations and recoveries
    checkCondition(
      newtradingPlan?.daily_profit_target > tradingPlan?.daily_profit_target,
      'daily_profit_target',
      newtradingPlan?.daily_profit_target <= tradingPlan?.daily_profit_target
    );

    checkCondition(
      newtradingPlan?.profit_target_per_trade >
        tradingPlan?.profit_target_per_trade,
      'profit_target_per_trade',
      newtradingPlan?.profit_target_per_trade <=
        tradingPlan?.profit_target_per_trade
    );

    checkCondition(
      newtradingPlan?.maximum_daily_loss < -tradingPlan?.maximum_daily_loss,
      'maximum_daily_loss',
      newtradingPlan?.maximum_daily_loss > -tradingPlan?.maximum_daily_loss
    );

    checkCondition(
      newtradingPlan?.stop_loss < tradingPlan?.stop_loss,
      'stop_loss',
      newtradingPlan?.stop_loss >= tradingPlan?.stop_loss
    );

    checkCondition(
      newtradingPlan?.maximum_trades_per_day >
        tradingPlan?.maximum_trades_per_day,
      'maximum_trades_per_day',
      newtradingPlan?.maximum_trades_per_day <=
        tradingPlan?.maximum_trades_per_day
    );

    checkCondition(
      newtradingPlan?.maximum_position_size >
        tradingPlan?.maximum_position_size,
      'maximum_position_size',
      newtradingPlan?.maximum_position_size <=
        tradingPlan?.maximum_position_size
    );

    checkCondition(
      newtradingPlan?.avoid_time_of_day === true,
      'avoid_time_of_day',
      newtradingPlan?.avoid_time_of_day === false
    );

    checkCondition(
      newtradingPlan?.avoid_day_of_week === true,
      'avoid_day_of_week',
      newtradingPlan?.avoid_day_of_week === false
    );
  }, [newtradingPlan, tradingPlan, hasTradeOccurred, violations]);

  useEffect(() => {
    console.log(`Consistency value after update: ${consistency}%`);
  }, [consistency]);

  return (
    <>
      <div className="main_page">
        <Header />
        <div className="left_container">
          {accounts.length === 0 ? (
            <Loader />
          ) : (
            <>
              <SubHeader
                accountId={accountId}
                accounts={accounts}
                handleSelectAccount={handleSelectAccount}
                onRefresh={onRefresh}
                loader2={loader2}
              />
              {allTradesLoader && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0
                  }}
                >
                  <Loader />
                </div>
              )}

              <div className="content-wrapper">
                <div className="row">
                  <div className="col-xl-12 col-lg-12">
                    <div className="row  align-items-center">
                      <div className="col-lg-12">
                        <div
                          className="consistency"
                          style={{ marginBottom: '20px', marginTop: '10px' }}
                        >
                          <div className="animated-progress progress">
                            <div
                              className="progress-bar bg-danger"
                              style={{
                                width: `${consistency}%`
                              }}
                            ></div>
                          </div>
                          <div className="consistency-value">
                            <div className="con-value">0-30%</div>
                            <div className="con-value">30-80%</div>
                            <div className="con-value">80-100%</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-8">
                        {/*Today’s Summary component*/}
                        <div className="row">
                          <div className="col-lg-3 col-md-6 ps-2 pe-2">
                            <div className="card widget-content">
                              <div className="widget-heading"> Closed P/L</div>
                              <div className="widget-subheading">
                                <span
                                  className={
                                    calculation?.closed_pl < 0 ? 'red' : 'green'
                                  }
                                >
                                  {calculation?.closed_pl !== null &&
                                  calculation?.closed_pl !== undefined
                                    ? calculation?.closed_pl === 0
                                      ? 'N/A'
                                      : (calculation?.closed_pl < 0
                                          ? '-'
                                          : '') +
                                        '$' +
                                        Math.abs(
                                          Math.round(
                                            calculation?.closed_pl ?? 0
                                          )
                                        ).toLocaleString()
                                    : 'N/A'}
                                </span>

                                {/* <span
                                  className={
                                    headings?.closed_pl < 0 ? 'red' : 'green'
                                  }
                                >
                                  {headings?.closed_pl !== null &&
                                    headings?.closed_pl !== undefined
                                    ? (headings?.closed_pl < 0 ? '-' : '') +
                                    '$' +
                                    Math.abs(headings?.closed_pl)
                                    : 'N/A'}
                                </span> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 ps-2 pe-2">
                            <div className="card widget-content">
                              <div className="widget-heading">Expectancy</div>
                              <div className="widget-subheading">
                                <span
                                  className={
                                    calculation?.expectancy < 0
                                      ? 'red'
                                      : 'green'
                                  }
                                >
                                  {calculation?.expectancy !== null &&
                                  calculation?.expectancy !== undefined
                                    ? calculation?.expectancy === 0
                                      ? 'N/A'
                                      : (calculation?.expectancy < 0
                                          ? '-'
                                          : '') +
                                        '$' +
                                        Math.abs(
                                          Math.round(
                                            calculation?.expectancy ?? 0
                                          )
                                        ).toLocaleString()
                                    : 'N/A'}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 ps-2 pe-2">
                            <div className="card widget-content">
                              <div className="widget-heading">
                                Profit Factor
                              </div>
                              <div className="widget-subheading">
                                {calculation?.profit_factor === '∞' ? (
                                  <div>
                                    <Infiniti className="infinity-icon" />
                                    <div className="no-loosing-trades">
                                      No Losing Trades
                                    </div>
                                  </div>
                                ) : calculation?.profit_factor ? (
                                  <span
                                    className={
                                      parseFloat(calculation?.profit_factor) > 0
                                        ? 'green'
                                        : 'red'
                                    }
                                  >
                                    {calculation?.profit_factor}
                                  </span>
                                ) : (
                                  <span className="green">N/A</span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 ps-2 pe-2">
                            <div className="card widget-content">
                              <div className="widget-heading">Win Rate</div>
                              <div className="widget-subheading">
                                <span
                                  className={
                                    calculation?.win_rate
                                      ? parseFloat(calculation?.win_rate) > 0
                                        ? 'green'
                                        : 'red'
                                      : 'green' // Default to green for 'N/A'
                                  }
                                >
                                  {calculation?.win_rate
                                    ? `${calculation?.win_rate}%`
                                    : 'N/A'}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* ALL TRADES component */}
                      <div className="col-xl-4">
                        <div className="days-component">
                          {/* ALL TRADES HEADING */}
                          {/* <ul className=" nav nav-pills" role="tablist">
                            <li className=" col-lg-2 nav-item">
                              <button
                                className={`nav-link ${
                                  day === '30' ? 'active' : ''
                                } fill-available`}
                                onClick={() => {
                                  setDay('30');
                                  setIsYtd(false);
                                }}
                              >
                                30D
                              </button>
                            </li>
                            <li className="col-lg-2 nav-item">
                              <button
                                className={`nav-link ${
                                  day === '90' ? 'active' : ''
                                } fill-available`}
                                onClick={() => {
                                  setDay('90');
                                  setIsYtd(false);
                                }}
                              >
                                90D
                              </button>
                            </li>
                            <li className="col-lg-2 nav-item">
                              <button
                                className={`nav-link ${
                                  day === '180' ? 'active' : ''
                                } fill-available`}
                                onClick={() => {
                                  setDay('180');
                                  setIsYtd(false);
                                }}
                              >
                                6M
                              </button>
                            </li>
                            <li className="col-lg-2 nav-item">
                              <button
                                className={`nav-link ${
                                  isYtd === true ? 'active' : ''
                                } fill-available`}
                                onClick={() => {
                                  const desiredPastDate = new Date(
                                    '2023-01-01'
                                  );
                                  const currentDate = new Date();
                                  const timeDifference =
                                    currentDate - desiredPastDate;
                                  const daysDifference = Math.floor(
                                    timeDifference / (24 * 60 * 60 * 1000)
                                  );
                                  setDay(daysDifference.toString());
                                  setIsYtd(true);
                                }}
                              >
                                YTD
                              </button>
                            </li>
                            <li className="col-lg-2 nav-item">
                              <button
                                className={`nav-link ${
                                  day === '365' ? 'active' : ''
                                } fill-available`}
                                onClick={() => {
                                  setDay('365');
                                  setIsYtd(false);
                                }}
                              >
                                1Y
                              </button>
                            </li>
                            <li className="col-lg-2 nav-item">
                              <button
                                className={`nav-link ${
                                  day === '1095' ? 'active' : ''
                                } fill-available`}
                                onClick={() => {
                                  setDay('1095');
                                  setIsYtd(false);
                                }}
                              >
                                3Y
                              </button>
                            </li>
                          </ul> */}
                          {/* <div className="tab-content">
                            <div
                              className="container tab-pane active"
                              style={{ minHeight: 300 }}
                            >
                              <div
                                style={{
                                  position: 'absolute',
                                  top: '20%',
                                  left: '45%',
                                  transform: 'translate(-50%, -50)'
                                }}
                              >
                                {allTradesLoader && <Loader size={50} />}
                              </div>
                              <>
                                {allTrades && (
                                  <>
                                    <ul className="p-0">
                                      <p className="daystitle mb-2">
                                        <span>ALL TRADES</span>
                                      </p>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Gross P/L :</p>
                                        <p className="text-end">
                                          {allTrades?.gross_pl
                                            ? (allTrades.gross_pl >= 0
                                                ? ''
                                                : '-') +
                                              '$' +
                                              Math.abs(
                                                allTrades.gross_pl ?? 0
                                              ).toLocaleString()
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Number of Trades :</p>
                                        <p className="text-end">
                                          {allTrades?.num_trades
                                            ? allTrades?.num_trades
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Avg. Trade Time :</p>
                                        <p className="text-end">
                                          {allTrades?.avg_trade_time &&
                                          allTrades?.avg_trade_time !==
                                            '0 days, 0:00:00'
                                            ? allTrades.avg_trade_time
                                            : 'N/A'}
                                        </p>
                                      </li>

                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Longest Trade Time :</p>
                                        <p className="text-end">
                                          {allTrades?.longest_trade_time &&
                                          allTrades?.longest_trade_time !==
                                            '0 days, 0:00:00'
                                            ? allTrades.longest_trade_time
                                            : 'N/A'}
                                        </p>
                                      </li>

                                      <li className="d-flex justify-content-between gap-4">
                                        <p>% Profitable Trades :</p>
                                        <p className="text-end">
                                          {allTrades?.percentage_profitable_trades
                                            ? allTrades?.percentage_profitable_trades
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Expectancy :</p>
                                        <p className="text-end">
                                          {allTrades?.expectancy
                                            ? (allTrades.expectancy >= 0
                                                ? '$'
                                                : '-$') +
                                              Math.abs(allTrades.expectancy)
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Trade Fees & Comm. :</p>
                                        <p className="text-end">
                                          {allTrades?.gross_pl
                                            ? (allTrades.gross_pl < 0
                                                ? '-$'
                                                : '$') +
                                              Math.abs(
                                                allTrades.gross_pl ?? 0
                                              ).toLocaleString()
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Total P/L :</p>
                                        <p className="text-end">
                                          {allTrades?.total_pl
                                            ? (allTrades.total_pl < 0
                                                ? '-$'
                                                : '$') +
                                              Math.abs(
                                                allTrades.total_pl ?? 0
                                              ).toLocaleString()
                                            : 'N/A'}
                                        </p>
                                      </li>
                                    </ul>
                                    <ul className="p-0">
                                      <p className="daystitle success mb-2">
                                        <span>PROFIT TRADES</span>
                                      </p>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Total Profit :</p>
                                        <p className="text-end">
                                          {profit_metrics?.total_profit
                                            ? (profit_metrics.total_profit < 0
                                                ? '- $'
                                                : '$') +
                                              Math.abs(
                                                profit_metrics.total_profit ?? 0
                                              ).toLocaleString()
                                            : 'N/A'}
                                        </p>
                                      </li>

                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Number of Winning Trades :</p>
                                        <p className="text-end">
                                          {profit_metrics?.num_winning_trades
                                            ? profit_metrics?.num_winning_trades
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Number of Winning Contracts :</p>
                                        <p className="text-end">
                                          {profit_metrics?.num_winning_contracts
                                            ? profit_metrics?.num_winning_contracts
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Largest Winning Trade :</p>
                                        <p className="text-end">
                                          {profit_metrics?.largest_winning_trade
                                            ? '$' +
                                              Math.abs(
                                                profit_metrics?.largest_winning_trade ??
                                                  0
                                              ).toLocaleString()
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Avg. Winning Trade :</p>
                                        <p className="text-end">
                                          {profit_metrics?.avg_winning_trade
                                            ? '$' +
                                              Math.abs(
                                                profit_metrics?.avg_winning_trade ??
                                                  0
                                              ).toLocaleString()
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Std. Dev. Winning Trade :</p>
                                        <p className="text-end">
                                          {profit_metrics?.std_dev_winning_trade
                                            ? '$' +
                                              Math.abs(
                                                profit_metrics?.std_dev_winning_trade ??
                                                  0
                                              ).toLocaleString()
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p style={{ whiteSpace: 'nowrap' }}>
                                          Avg. Winning Trade Time :
                                        </p>
                                        <p className="text-end">
                                          {profit_metrics?.avg_winning_trade_time &&
                                          profit_metrics?.avg_winning_trade_time !==
                                            '0 days, 0:00:00'
                                            ? profit_metrics.avg_winning_trade_time
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p style={{ wordBreak: 'break-all' }}>
                                          Longest Winning Trade Time :
                                        </p>
                                        <p className="text-end">
                                          {profit_metrics?.longest_winning_trade_time &&
                                          profit_metrics?.longest_winning_trade_time !==
                                            '0 days, 0:00:00'
                                            ? profit_metrics.longest_winning_trade_time
                                            : 'N/A'}
                                        </p>
                                      </li>

                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Max Run-up :</p>
                                        <p className="text-end">
                                          {profit_metrics?.maxrunup
                                            ? '$' +
                                              Math.abs(
                                                profit_metrics?.maxrunup ?? 0
                                              ).toLocaleString()
                                            : 'N/A'}
                                        </p>
                                      </li>
                                    </ul>
                                    <ul className="p-0 m-0">
                                      <p className="daystitle danger mb-2">
                                        <span>LOSING TRADES</span>
                                      </p>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Total Loss :</p>
                                        <p className="text-end">
                                          {loss_metrics?.total_loss
                                            ? (loss_metrics.total_loss < 0
                                                ? '- $'
                                                : '$') +
                                              Math.abs(
                                                loss_metrics.total_loss ?? 0
                                              ).toLocaleString()
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Number of Losing Trades :</p>
                                        <p className="text-end">
                                          {loss_metrics?.num_losing_trades
                                            ? loss_metrics?.num_losing_trades
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Number of Losing Contracts :</p>
                                        <p className="text-end">
                                          {loss_metrics?.num_losing_contracts
                                            ? loss_metrics?.num_losing_contracts
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Largest Losing Trade :</p>
                                        <p className="text-end">
                                          {loss_metrics?.largest_losing_trade
                                            ? (loss_metrics.largest_losing_trade <
                                              0
                                                ? '- $'
                                                : '$') +
                                              Math.abs(
                                                loss_metrics.largest_losing_trade ??
                                                  0
                                              ).toLocaleString()
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Avg.Losing Trade :</p>
                                        <p className="text-end">
                                          {loss_metrics?.avg_losing_trade
                                            ? '- $' +
                                              Math.abs(
                                                loss_metrics?.avg_losing_trade ??
                                                  0
                                              ).toLocaleString()
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Std. Dev. Losing Trade : </p>
                                        <p className="text-end">
                                          {loss_metrics?.std_dev_losing_trade
                                            ? '- $' +
                                              Math.abs(
                                                loss_metrics?.std_dev_losing_trade ??
                                                  0
                                              ).toLocaleString()
                                            : 'N/A'}
                                        </p>
                                      </li>
                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Avg. Losing Trade Time :</p>
                                        <p className="text-end">
                                          {loss_metrics?.avg_losing_trade &&
                                          loss_metrics?.avg_losing_trade !==
                                            '0 days, 0:00:00'
                                            ? loss_metrics.avg_losing_trade
                                            : 'N/A'}
                                        </p>
                                      </li>

                                      <li className="d-flex justify-content-between gap-4">
                                        <p style={{ whiteSpace: 'nowrap' }}>
                                          Longest Losing Trade Time :
                                        </p>
                                        <p className="text-end">
                                          {loss_metrics?.longest_losing_trade_time &&
                                          loss_metrics?.longest_losing_trade_time !==
                                            '0 days, 0:00:00'
                                            ? loss_metrics.longest_losing_trade_time
                                            : 'N/A'}
                                        </p>
                                      </li>

                                      <li className="d-flex justify-content-between gap-4">
                                        <p>Max Drawdown : </p>

                                        <p className="text-end">
                                          {loss_metrics?.maxdrawdown
                                            ? '- $' +
                                              Math.abs(
                                                loss_metrics?.maxdrawdown ?? 0
                                              ).toLocaleString()
                                            : 'N/A'}
                                        </p>
                                      </li>
                                    </ul>
                                  </>
                                )}
                              </>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="summary-chart">
                          <p
                            style={{
                              fontWeight: 'bold',
                              // textDecoration: 'underline',
                              fontSize: '20px'
                            }}
                          >
                            Today's Trade Summary
                          </p>
                          <div className="profit"></div>
                          <div className="threshold-container">
                            {allTradesLoader ? (
                              <p className="loading-text"></p> // Display loading text while waiting for data
                            ) : profitTarget !== null &&
                              profitTarget !== undefined ? (
                              <div className="threshold-box profit-box">
                                ${profitTarget.toLocaleString()} PT
                              </div>
                            ) : null}
                          </div>

                          <div style={{ width: '100%', height: '300px' }}>
                            {allTradesLoader ? (
                              <p className="loading-text"></p>
                            ) : tradeChart2 ? (
                              <Line data={tradeChart2} options={options} />
                            ) : null}
                          </div>
                          <div className="threshold-container">
                            {allTradesLoader ? (
                              <p className="loading-text"></p> // Display loading text while waiting for data
                            ) : lossTarget !== null &&
                              lossTarget !== undefined ? (
                              <div className="threshold-box loss-box">
                                -${Math.abs(lossTarget).toLocaleString()} ML
                              </div>
                            ) : null}
                          </div>

                          {/*Today’s Trades component*/}
                          <div className="trades-block">
                            <p
                              style={{
                                fontWeight: 'bold',
                                // textDecoration: 'underline',
                                fontSize: '20px'
                              }}
                            >
                              Today's Trades
                            </p>
                            <TradeTable
                              accountNo={selectedAccount}
                              isLoading={isLoading} // Pass the selected account number here
                              appliedFilters={appliedFilters} // Pass the applied filters if any
                              onRowPress={(trade) => showInsight(trade)} // Callback to show insights when row is clicked
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div>
            {selectedLogin && selectedAccount && (
              <ChatbotMobileTradePlan
                from={'account'}
                selectedAccount={selectedAccount}
                selectedLogin={selectedLogin}
                // isMobile ={isMobile}
              />
            )}
          </div>
        </div>
        <div className="right_container">
          {selectedLogin && selectedAccount && (
            <ChatbotWebTradePlan
              from={'account'}
              selectedAccount={selectedAccount}
              selectedLogin={selectedLogin}
            />
          )}
        </div>
      </div>

      <Modal
        isOpen={insightModal.show}
        toggle={onCloseInsightModal}
        className="trades-details-modal"
        size="lg"
        centered
      >
        <ModalHeader className="border-0 pb-0">
          Trade Insight
          <img
            alt="CrossIcon"
            src={CrossIcon}
            style={{ padding: '5px', float: 'right', cursor: 'pointer' }}
            onClick={onCloseInsightModal}
          />
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-lg-8">
              <p className="mb-2">How did I feel?</p>
              <div className="check-emoje">
                <span
                  className={`emoje ${
                    selectedEmojis === 'laugh' ? 'selected' : ''
                  }`}
                  onClick={() => handleEmojiClick('laugh')}
                  style={{ cursor: 'pointer', userSelect: 'none' }}
                >
                  <Smile
                    className={`${
                      selectedEmojis === 'laugh' && selectedEmojis.length > 0
                        ? 'emojeIcon'
                        : ''
                    }`}
                  />
                </span>
                <span
                  className={`emoje ${
                    selectedEmojis === 'smile' ? 'selected' : ''
                  }`}
                  onClick={() => handleEmojiClick('smile')}
                  style={{ cursor: 'pointer', userSelect: 'none' }}
                >
                  <Smile2
                    className={`${
                      selectedEmojis === 'smile' && selectedEmojis.length > 0
                        ? 'emojeIcon'
                        : ''
                    }`}
                  />
                </span>
                <span
                  className={`emoje ${
                    selectedEmojis === 'speechless' ? 'selected' : ''
                  }`}
                  onClick={() => handleEmojiClick('speechless')}
                  style={{ cursor: 'pointer', userSelect: 'none' }}
                >
                  <Emotion
                    className={`${
                      selectedEmojis === 'speechless' &&
                      selectedEmojis.length > 0
                        ? 'emojeIcon'
                        : ''
                    }`}
                  />
                </span>
                <span
                  className={`emoje ${selectedEmojis === 'sad' ? 'sad' : ''}`}
                  onClick={() => handleEmojiClick('sad')}
                  style={{ cursor: 'pointer', userSelect: 'none' }}
                >
                  <Sadface
                    className={`${
                      selectedEmojis === 'sad' && selectedEmojis.length > 0
                        ? 'emojeIcon'
                        : ''
                    }`}
                  />
                </span>
                <span
                  className={`emoje ${selectedEmojis === 'angry' ? 'sad' : ''}`}
                  onClick={() => handleEmojiClick('angry')}
                  style={{ cursor: 'pointer', userSelect: 'none' }}
                >
                  <SadAngry
                    className={`${
                      selectedEmojis === 'angry' && selectedEmojis.length > 0
                        ? 'emojeIcon'
                        : ''
                    }`}
                  />
                </span>
              </div>
              <p className="mb-2">Did I follow my trading plan?</p>
              <div className="toggle-switch mb-3">
                <div className="can-toggle">
                  <input
                    id="a"
                    type="checkbox"
                    checked={toggleValue === 'No'}
                    onChange={handleToggleChange}
                  />
                  <label htmlFor="a">
                    <div
                      className="can-toggle__switch"
                      data-checked="Yes"
                      data-unchecked="No"
                    ></div>
                  </label>
                </div>
              </div>
              <p className="mb-1">Reflections</p>
              <textarea
                rows={4}
                style={{ padding: '10px' }}
                className="reflections-textarea"
                value={reflections}
                onChange={handleReflections}
              ></textarea>
              <div>
                <p className="text-end">{wordCount}/250</p>
              </div>
              <div style={{ height: 20 }}>
                {error ? (
                  <p className="reflection-error">{error}</p>
                ) : reflectionMessage !== '' ? (
                  <p className="reflection-error">{reflectionMessage}</p>
                ) : (
                  ''
                )}
              </div>
              <div className="d-flex align-items-center gap-2 my-2">
                <button
                  className="btn btn-xs btn-secondary"
                  onClick={saveTradeInsight}
                >
                  {' '}
                  {isLoading ? 'Please wait...' : 'Save'}
                </button>
                <button
                  className="btn btn-xs btn-outline-dark"
                  onClick={onCloseInsightModal}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="tradeLog-details m-0">
                <div className="tradelog-list">
                  <p>
                    <span
                      className={
                        focusedTrade?.['type'] === TradeSide.BUY
                          ? 'buy'
                          : 'sell'
                      }
                      style={{ display: 'inline-block' }}
                    >
                      {focusedTrade?.['type']}
                    </span>
                  </p>
                  <p>{focusedTrade?.volume}</p>
                  <p>{focusedTrade?.symbol}</p>
                  <p>
                    <b>Entry:</b>
                    <br />
                    {getFormattedDateTime(focusedTrade?.tradeTime)}
                    <br />@{formatPrice(focusedTrade?.openPrice)}
                  </p>
                  <p>
                    <b>Exit:</b>
                    <br />
                    {getFormattedDateTime(focusedTrade?.closingTime)}
                    <br />@{formatPrice(focusedTrade?.currentPrice)}
                  </p>
                </div>
                <h5
                  className={
                    focusedTrade?.currentProfit &&
                    focusedTrade?.currentProfit > 0
                      ? 'green'
                      : 'red'
                  }
                >
                  {forceUnaryOperator(focusedTrade?.currentProfit)}
                </h5>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default HomeComponent;
