/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import React, { useState, useEffect, useRef } from 'react';
import { useIsMobile } from '../../utils/helper';
import { Modal, ModalBody } from 'reactstrap';
import Header from '../Header/component';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { useSelector } from 'app/store';
import { formatAsParam } from 'utils/date';
import './styles.scss';
import NewUserAccount from '../../features/new-user-account/component';
import ChatbotMobileTradePlan from 'components/chatbot/chatbotMobileTradePlan';
import TradeTable from 'components/trades/tradeTable2';
import axios from 'axios';
import {
  Infiniti,
} from 'assets/svgIcons';
import {
  getSelectedAccount,
  selectAccounts,
  setUserdetails,
  updateAccounts,
  setDefaultAccount,
  getDefaultAccount
} from 'features/login/loginSlice';
import { Line } from 'react-chartjs-2';
import CrossIcon from '../../assets/images/cross-icon.svg';
import { useParams } from 'react-router-dom';
import Loader from 'components/loader/loader';
import { getUserDetails } from 'features/profile-screen/userApi';
import { useDispatch } from 'react-redux';
import ChatbotWebTradePlan from '../../components/chatbot/ChatbotWebTradePlan';
import {
  clearChatMessage,
  setCurrentAccount,
  setCurrentLogin,
  sendChatMessageThunk,
  addUserMessage
} from '../../components/chatbot/ChatSlice';
import { getTradingPlan } from 'components/chatbot/tradingPlanSlice';
import { AppDispatch } from '../../app/store';
import { getAllTradeJournal } from './journalSlice';
import { io } from 'socket.io-client';
// Register chart.js modules
import {
  Chart as ChartJS,
  ChartOptions,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import AppConfig from '../../config';
// import { setLocale } from 'yup';
// import { setLabels } from 'react-chartjs-2/dist/utils';

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const URL = 'wss://api.fractalalpha.com/deals';
const Helper = require('../../utils/helper');
const localizer = momentLocalizer(moment);
type InputRange = Date[] | { start: Date; end: Date };
type Range = { start: string | null; end: string | null } | undefined;

// Define the structure of the chart data
interface ChartData {
  labels: number[]; // X-axis labels
  datasets: {
    label: string;
    data: number[];
    fill: boolean;
    borderColor: string;
    backgroundColor: string;
    borderWidth: number;
    pointRadius: number;
    tension: number;
  }[];
}

const options2: ChartOptions<'line'> = {
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    x: {
      title: {
        display: false,
        text: 'Number of Trades' // X-axis label
      },
      grid: {
        display: false // Removes vertical grid lines
      },
      beginAtZero: true
    },
    y: {
      title: {
        display: false,
        text: 'Profit & Loss' // Y-axis label
      },
      beginAtZero: true // Ensure Y-axis starts at zero
    }
  },
  plugins: {
    legend: {
      display: false,
      position: 'top'
    },
    tooltip: {
      callbacks: {
        title: function (tooltipItems) {
          const label = tooltipItems[0].label; // X-axis label
          return `Trade: ${label}`;
        },
        label: function (tooltipItem) {
          const value = tooltipItem.raw as number; // Y-axis value
          if (value > 0) {
            return `Profit: $${value}`;
          } else {
            return `Loss: $${Math.abs(value)}`;
          }
        },
        labelColor: function (tooltipItem) {
          const value = tooltipItem.raw as number; // Y-axis value
          if (value > 0) {
            return {
              backgroundColor: '#4CAF50', // Green box for profit
              borderColor: '#4CAF50'
            };
          } else {
            return {
              backgroundColor: 'red', // Red box for loss
              borderColor: 'red'
            };
          }
        }
      }
    }
  }
};

const defaultLabel = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20
];

interface Trade {
  currentProfit?: number;
  symbol?: string;
  volume?: number;
  tradeTime?:string
  openPrice:number
  closingTime:number
  currentPrice:number
}

const TradeJournal = (props) => {
  const tradeQueue = useRef<Trade[]>([]);
  const DefaultAccount = useSelector(getDefaultAccount);
  const { events, account_id } = useSelector((state) => state.tradesJournal);

  // const location = useLocation();
  // const journal = location?.state?.analysisPage;
  const { accountId } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const [activeAccount, setActiveAccount] = useState('');
  const [InitiateModal, setInitiateModal] = useState(true);
  const InitiateModaltoggle = () => setInitiateModal(!InitiateModaltoggle);
  const [IsNewUser, setNewUser] = useState(false);
  const allAccounts = useSelector(selectAccounts);
  const isMobile = useIsMobile();
  const accounts = allAccounts && allAccounts?.filter(
    (account) => account.status === 'active'
  );
  const [selectedAccount, setSelectedAccount] = useState<any>(
    DefaultAccount
      ? DefaultAccount
      : accountId || (accounts.length > 0 ? accounts && accounts[0].account_id : null)
  );

  const [dateRange, setDateRange] = useState<Range>();
  const [loading, setLoading] = useState(false);

  const [JournalModal, setJournalModal] = useState(false);
  const [selectEvent, setSelectEvent] = useState(null);
  const [date, setDate] = useState<Date | null>(null);
  const [tradeDetailedTbl, setTradeDetailedTbl] = useState({
    trades: []
  });
  const [calculation, setCalculation] = useState({
    closed_pl: '',
    expectancy: '',
    profit_factor: '',
    win_rate: ''
  });
  const [trades, setTrades] = useState({
    closingTime: ''
  });
  const accountIds = useSelector(getSelectedAccount);
  const [selectedLogin, setSelectedLogin] = useState('');
  const [tradeLables, setTradeLables] = useState<number[]>(defaultLabel);
  const [numberOfTrade, setNumberOfTrade] = useState(20);
  const [tradeChart2, setTradeChart2] = useState<ChartData>({
    labels: [], // X-axis labels (Number of Trades)
    datasets: [
      {
        label: '', // Legend label
        data: [0], // Y-axis data (Profit & Loss values)
        fill: true,
        borderColor: 'rgba(75, 192, 192, 1)', // Line color
        backgroundColor: 'rgba(75, 192, 192, 0.2)', // Fill color
        borderWidth: 3,
        pointRadius: 0,
        tension: 0.2 // Curve tension
      }
    ]
  });
  const [tradeQueueCurrent, setTradeQueueCurrent] = useState<Trade[]>([]);

  useEffect(() => {
    if (numberOfTrade > 20) {
      const trade = [...tradeLables];
      for (let i = 21; i <= numberOfTrade; i++) {
        trade.push(i);
      }
      setTradeChart2((prevState) => ({
        ...prevState,
        labels: trade, // Update X-axis
        datasets: [
          {
            ...prevState.datasets[0]
          }
        ]
      }));
    } else {
      setTradeLables(defaultLabel);
      setTradeChart2((prevState) => ({
        ...prevState,
        labels: tradeLables, // Update X-axis
        datasets: [
          {
            ...prevState.datasets[0]
          }
        ]
      }));
    }
  }, [numberOfTrade]);

  useEffect(() => {
    const socket = io(URL, {
      query: { login: selectedLogin },
      reconnectionAttempts: 5, // Attempt to reconnect up to 5 times
      reconnectionDelay: 2000 // Delay between reconnection attempts
    });

    socket.on('connect', () => {
      console.log('WebSocket connected');
    });

    socket.on('disconnect', () => {
      console.log('WebSocket disconnected');
    });

    socket.on('error', (error) => {
      console.error('WebSocket error:', error);
    });

    socket.on('new_trade', (trade) => {
      if (trade) {
        console.log('WebSocket calling...', trade);
        tradeQueue.current.push(trade);
        setTradeQueueCurrent([...tradeQueue.current]);
      }
    });

    return () => {
      socket.close();
      console.log('WebSocket connection closed');
    };
  }, [selectedLogin]);

  useEffect(() => {
    if (tradeQueue.current && tradeQueue.current.length > 0) {
      tradeQueue.current.forEach((data) => {
        if (data.currentProfit && data.currentProfit > 0) {
          const profitMessage = `🎉 Congratulations! You just closed a profitable trade on ${data.symbol} you earned $${data.currentProfit} with a volume of ${data.volume} lots. Keep up the good work! 💰`;
          dispatch(addUserMessage(Helper.getProfitMessage(profitMessage)));
        }
        if (data.currentProfit && data.currentProfit < 0) {
          const lossMessage = `⚠️ Trade Closed. Unfortunately, your trade on ${data.symbol} resulted in a loss of $${data.currentProfit} Don't worry, there's always another opportunity ahead!`;
          dispatch(addUserMessage(Helper.getLossMessage(lossMessage)));
        }
      });
    }
    tradeQueue.current = [];
  }, [tradeQueueCurrent]);


  const toggleJournalModal = () => {
    setJournalModal(!JournalModal);
    if (!JournalModal) {
      setTradeDetailedTbl({ trades: [] });
    }
  };

  const parseDateInput = (input: InputRange): Range => {
    if (Array.isArray(input)) {
      if (input?.length === 1) {
        const startDate = input[0];
        const endDate = new Date(startDate);
        endDate.setHours(23, 59, 59, 999);
        return {
          start: formatAsParam(startDate),
          end: formatAsParam(endDate)
        };
      } else if (input.length === 2) {
        const [startDate, endDate] = input;
        return {
          start: formatAsParam(startDate),
          end: formatAsParam(endDate)
        };
      } else if (input.length === 7) {
        const sortedDates = input.sort(
          (a, b) => new Date(a).getTime() - new Date(b).getTime()
        );
        const startDate = sortedDates[0];
        const endDate = sortedDates[sortedDates.length - 1];
        return {
          start: formatAsParam(startDate),
          end: formatAsParam(endDate)
        };
      } else {
        console.error(
          'Invalid date array. It should contain either one date, two dates, or exactly seven dates for a week.'
        );
        return undefined;
      }
    } else if (
      typeof input === 'object' &&
      input !== null &&
      'start' in input &&
      'end' in input
    ) {
      return {
        start: formatAsParam(new Date(input.start)),
        end: formatAsParam(new Date(input.end))
      };
    } else {
      console.error(
        "Invalid date input. It should be either an array of dates or an object with 'start' and 'end' properties."
      );
      return undefined;
    }
  };

  useEffect(() => {
    const selected_account = accounts && accounts.find(
      (account) => account.account_id === selectedAccount
    );
    if (selected_account) {
      dispatch(setCurrentAccount(selected_account.account_id));
      dispatch(setCurrentLogin(selected_account.login));
      setSelectedLogin(selected_account.login);
    }
  }, [accountId, accounts, selectedAccount]);

  useEffect(() => {
    if (accounts.length === 0) {
      // Call user details API to fetch account information
      const fetchUserDetails = async () => {
        try {
          // Make API call to fetch user details
          const data = await getUserDetails();
          dispatch(setUserdetails(data));
          dispatch(updateAccounts(data?.accounts || []));

          if (data.accounts.length > 0) {
            const firstActiveAccount = data.accounts.find(
              (account) => account.status === 'active'
            );
            if (!accountId) {
              if (firstActiveAccount) {
                setSelectedAccount(firstActiveAccount.account_id);
                dispatch(setDefaultAccount(firstActiveAccount.account_id));
              }
            } else {
              setSelectedAccount(accountId);
              dispatch(setDefaultAccount(accountId));
            }
          } else {
            // Handle case where no active account is found
            console.error('No active accounts found for the user.');
          }
        } catch (error) {
          // Handle error
          console.error('Error fetching user details:', error);
        }
      };

      fetchUserDetails();
    }
  }, []);

  useEffect(() => {
    if (selectedAccount) {
      if (selectedAccount !== account_id) {
        setLoading(true);
      }
      dispatch(getTradingPlan(selectedAccount));
      fetchTradesJournal();
    }
  }, [selectedAccount]);

  const handleEventClick = (event) => {
    setSelectEvent(event);
    setDate(event.start);
    toggleJournalModal();
    fetchTradeDetails(event.start);
  };

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    Helper.isTokenExpired(token);
  }, []);

  useEffect(() => {
    setNewUser(Helper.checkIfNewUser(accounts));
  }, []);

  const handleChange = (e) => {
    setDateRange({ start: '', end: '' });
    if (accountId) {
      window.history.pushState({}, '', `/journal/${e.target.value}`);
    }
    setSelectedAccount(e.target.value);

    dispatch(setDefaultAccount(e.target.value));
    dispatch(clearChatMessage(true));
  };

  const fetchTradesJournal = async () => {
    try {
      if (!dateRange) {
        const today = new Date();
        const firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        );
        const lastDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );
        setDateRange({
          start: formatAsParam(firstDayOfMonth),
          end: formatAsParam(lastDayOfMonth)
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 0));
      await dispatch(getAllTradeJournal(selectedAccount));
      setLoading(false);

      // We will use for future use
      {
        /*
        const { dateSummary } = await restService.privateRequest<any>({
          method: RequestMethod.GET,
          endpoint: `/${Endpoints.TRADE_JOURNAL}`,
          queryParams: {
            account_id: selectedAccount,
            ...(dateRange && dateRange.start && dateRange.end
              ? {
                  start_date: dateRange.start ?? '',
                  end_date: dateRange.end ?? ''
                }
              : {})
          },
          accountSecrets: { jwt: token || '' }
        });
        if (dateSummary && dateSummary.length > 0) {
          const formattedEvents = dateSummary.map((event) => {
            return {
              title: `Trades: ${event.number_of_trades}, Profit: ${event.total_profit}`,
              start: moment(event.date, 'YYYY.MM.DD').toDate(),
              end: moment(event.date, 'YYYY.MM.DD').toDate(),
              total_profit: event.total_profit,
              number_of_trades: event.number_of_trades
            };
          });
          setEvents(formattedEvents);
          setLoading(false);
        } else {
          setEvents([]);
          setDateRange({
            start: '',
            end: ''
          });
          setLoading(false);
        }
      */
      }
    } catch (error) {
      console.error('Error fetching trades:', error);
      setLoading(false);
    }
  };

  const fetchTradeDetails = async (eventDate) => {
    setLoading(true);
    setTradeDetailedTbl({ trades: [] });
    setCalculation({
      closed_pl: '',
      expectancy: '',
      profit_factor: '',
      win_rate: ''
    });
    setTradeChart2((prevState) => ({
      ...prevState,
      labels: defaultLabel, // Update X-axis
      datasets: [{ ...prevState.datasets[0] }]
    }));

    try {
      let token = localStorage.getItem('jwtToken');
      if (!eventDate) {
        return;
      }

      const formattedDate = eventDate
        ? moment(eventDate).format('YYYY.MM.DD')
        : null;

      const response = await axios.get(
        `${AppConfig.apiUrl}/trades_journal_details?account_id=${selectedAccount}&date=${formattedDate}`,
        {
          headers: {
            Authorization: `${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      const tradeDetails = response.data;
      const trades = tradeDetails.trades || [];

      const formattedTrades = trades.map((trade) => ({
        date: new Date(trade.tradeTime),
        profit: trade.currentProfit
      }));

      let tradeValues = formattedTrades.map((trade) => trade.profit).reverse();
      setNumberOfTrade(tradeValues.length);
      tradeValues && tradeValues.unshift(0);
      let result: number[] = [];
      tradeValues &&
        tradeValues.reduce((acc, curr) => {
          let data = acc + curr;
          result.push(Math.round(data));
          return data;
        }, 0);

      setTradeChart2((prevState) => ({
        ...prevState,
        labels: tradeLables, // Update X-axis
        datasets: [
          {
            ...prevState.datasets[0], // Preserve existing dataset structure
            data: result // Update Y-axis data
          }
        ]
      }));

      setCalculation(tradeDetails.calculation);
      setTrades(tradeDetails.trades);
      setTradeDetailedTbl({
        trades: trades || []
      });
      setLoading(false);
      // setDate(null);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching trade details:', error);
    }
  };

  return (
    <>
      <Header activeLink={'journal'} className="w-100" />
      {IsNewUser ? (
        <NewUserAccount
          InitiateModal={InitiateModal}
          InitiateModaltoggle={InitiateModaltoggle}
        />
      ) : (
        <>
          <div className="main_page">
            <div className="left_container">
              <div className="table-container">
                <>
                  <div className="table-sub-container">
                    <div className="fa-filter-block">
                      <div className="fa-filter">
                        <h6>Trade Journal</h6>
                        <div>
                          <select
                            className="form-select"
                            value={selectedAccount}
                            onChange={handleChange}
                            defaultValue={
                              accountId ? accountId : DefaultAccount
                            }
                          >
                            {accounts && accounts.map((account) => (
                              <option
                                key={account.account_id}
                                value={account.account_id}
                                selected={
                                  account.account_id ===
                                  (DefaultAccount || accountId)
                                }
                              >
                                Account #{account.account_id}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="journal-calendar">
                      <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor={'start'}
                        endAccessor={'end'}
                        titleAccessor={() => moment(date).format('D')}
                        onRangeChange={(values) => {
                          setDateRange(parseDateInput(values));
                        }}
                        style={{ height: 500 }}
                        views={['month', 'week']}
                        components={{
                          eventWrapper: (e) => {
                            const eventObject: any = e.event;

                            if (!eventObject) {
                              return null;
                            }
                            const { total_profit, start, number_of_trades } =
                              eventObject;

                            const formattedDate = moment(start).format('D');

                            return (
                              <div
                                className={
                                  total_profit < 0
                                    ? 'negative-data'
                                    : 'positive-data'
                                }
                                onClick={() => handleEventClick(e.event)}
                                style={{ cursor: 'pointer' }}
                              >
                                <p>
                                  {total_profit < 0
                                    ? `-$${Math.abs(
                                      total_profit ?? 0
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })}`
                                    : `$${Math.abs(
                                      total_profit ?? 0
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })}`}
                                </p>

                                <p>
                                  {number_of_trades > 1
                                    ? `${number_of_trades} Trades`
                                    : `${number_of_trades} Trade`}
                                </p>
                                <span className="date">{formattedDate}</span>
                              </div>
                            );
                          },
                          dateCellWrapper: ({ children, value }) => {
                            return React.cloneElement(
                              React.Children.only(children),
                              {
                                style: {
                                  position: 'relative'
                                },
                                children: (
                                  <>
                                    {children}
                                    <span className="date-label">
                                      {moment(value).format('D')}
                                    </span>
                                  </>
                                )
                              }
                            );
                          }
                        }}
                      />
                      {loading && <Loader />}
                    </div>
                  </div>
                </>
              </div>
            </div>
            <div className="right_container">
              <ChatbotWebTradePlan
                from={'account'}
                selectedAccount={selectedAccount}
                selectedLogin={selectedLogin}
              />
            </div>
          </div>
          {isMobile && (
            <div>
              <ChatbotMobileTradePlan
                from={'account'}
                selectedAccount={selectedAccount}
                selectedLogin={selectedLogin}
                activeAccounts={activeAccount}
              // isMobile={isMobile}
              />
            </div>
          )}
        </>
      )}

      <Modal
        isOpen={JournalModal}
        toggle={toggleJournalModal}
        size="xl"
        centered
      >
        <ModalBody>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h6>
              Journal Entry for { }
              <span className="green">
                {/* {moment(trades[0]?.closingTime).format('MMMM Do, YYYY')}
                 */}
                {moment(date).format('MMMM Do, YYYY')}
              </span>
            </h6>
            <div className="cross-icon-container" onClick={toggleJournalModal}>
              <img alt="cross" src={CrossIcon} className="cross-icon" />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="journal-entry ">
                <div>
                  <p>Closed P/L</p>

                  <h6>
                    <span
                      className={
                        calculation &&
                          !isNaN(parseFloat(calculation.closed_pl)) &&
                          parseFloat(calculation.closed_pl) < 0
                          ? 'red'
                          : 'green'
                      }
                    >
                      {calculation && !isNaN(parseFloat(calculation.closed_pl))
                        ? parseFloat(calculation.closed_pl) < 0
                          ? '-$' +
                          Math.abs(Math.round(
                            parseFloat(calculation.closed_pl) ?? 0
                          )).toLocaleString(undefined, {
                            // minimumFractionDigits: 2,
                            // maximumFractionDigits: 2
                          }) // ensures commas and two decimals
                          : '$' +
                          Math.abs(Math.round(
                            parseFloat(calculation.closed_pl) ?? 0
                          )).toLocaleString(undefined, {
                            // minimumFractionDigits: 2,
                            // maximumFractionDigits: 2
                          })
                        : '---'}
                    </span>
                  </h6>
                </div>
                <div>
                  <p>Expectancy</p>
                  <h6>
                    <span
                      className={calculation && calculation.expectancy != null && !isNaN(parseFloat(calculation.expectancy))
                        ? parseFloat(calculation.expectancy) > 0
                          ? 'green'
                          : 'red'
                        : ''
                      }
                    >

                      {calculation &&
                        calculation.expectancy != null &&
                        !isNaN(parseFloat(calculation.expectancy))
                        ? `${parseFloat(calculation.expectancy) > 0 ? '' : '-'}$${Math.abs(Math.round(
                          parseFloat(calculation.expectancy) ?? 0
                        )).toLocaleString(undefined, {
                          // minimumFractionDigits: 2,
                          // maximumFractionDigits: 2
                        })}`
                        : '---'}

                    </span>



                    {/* <span className="green">
                      {calculation && !isNaN(parseFloat(calculation.expectancy))
                        ? '$' + calculation.expectancy
                        : '---'}
                    </span> */}
                  </h6>
                </div>
                <div>
                  <p>Profit Factor</p>
                  <h6>
                    <span
                      className={`widget-subheading ${calculation?.profit_factor &&
                        calculation?.profit_factor !== '∞' &&
                        parseFloat(calculation?.profit_factor) > 0
                        ? 'green'
                        : ''
                        }`}
                    >
                      <b>
                        {calculation?.profit_factor === '∞' ? (
                          <div>
                            <Infiniti className="infinity-icon" />
                            <div className="no-losing-trades">No Losing Trades</div>
                          </div>
                        ) : calculation?.profit_factor ? (
                          calculation?.profit_factor
                        ) : (
                          '---'
                        )}
                      </b>
                    </span>
                  </h6>

                  {/* <h6>
                    <span className="green">
                      {calculation &&
                      !isNaN(parseFloat(calculation.profit_factor))
                        ? calculation.profit_factor
                        : '---'}
                    </span>
                  </h6> */}
                </div>
                <div>
                  <p>Win Rate</p>
                  <h6>
                    <span className="green">
                      {calculation && !isNaN(parseFloat(calculation.win_rate))
                        ? calculation.win_rate + '%'
                        : '---'}
                    </span>
                  </h6>
                </div>
              </div>
              {/* <p>consistency</p>
              <div className="consistency">
                <div className="animated-progress  bg-danger">
                  <div className="progress-bar" style={{ width: '80%' }}></div>
                </div>
                <div className="consistency-value">
                  <div className="con-value">0-30%</div>
                  <div className="con-value">30-80%</div>
                  <div className="con-value">80-100%</div>
                </div>
              </div> */}

              <div className="row progress-container">
                {!loading && (
                  <div className="col-lg-12" style={{ height: '320px' }}>
                    <Line data={tradeChart2} options={options2} />
                  </div>
                )}
              </div>

              <TradeTable
                table={tradeDetailedTbl.trades}
                accountNo={accountIds}
                onRowPress={() => { }}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TradeJournal;
