import { createSlice } from '@reduxjs/toolkit';
import { fetchTradeData, getCalculations, getGraphData } from './homeApi';

const initialState = {
  isLoading: false,
  data: null, // Holds the trade data
  calculation: {}, // Holds the calculation data
  graph_data: {
    // Holds the graph-related data
    closingTime: [],
    currentProfit: [],
    losses: 0,
    profits: 0
  },
  loader: false,
  error: null,
  isError: false,
  trades: [],
  totalCount: 0 // Initialize with an empty array for trades
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setUpdatedTrades: (state, action) => {
      state.trades.unshift(action.payload);
    }
  },
  extraReducers: (builder) => {
    builder

      // Handle trade data fetching states
      .addCase(fetchTradeData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchTradeData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload; // Assuming API response for trade data
      })
      .addCase(fetchTradeData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message;
      })

      // Handle calculations fetching states
      .addCase(getCalculations.pending, (state) => {
        state.loader = true;
        state.error = null;
      })
      .addCase(getCalculations.fulfilled, (state, action) => {
        state.loader = false;
        state.calculation = action.payload.calculation;
        state.graph_data = action.payload.graph_data;
      })
      .addCase(getCalculations.rejected, (state, action) => {
        state.loader = false;
        state.isError = true;
        state.error = action.payload?.message || 'Error fetching calculations';
      })

      // Handle graph data fetching states
      .addCase(getGraphData.pending, (state) => {
        state.loader = true;
        state.error = null;
      })
      .addCase(getGraphData.fulfilled, (state, action) => {
        state.loader = false;
        state.graph_data = action.payload.graph_data; // Graph data response
        state.calculation = action.payload.calculation; // Calculation data response
        state.trades = action.payload.trades || [];
        state.totalCount = action.payload.total_count;
      })
      .addCase(getGraphData.rejected, (state, action) => {
        state.loader = false;
        state.isError = true;
        state.error = action.payload?.message || 'Error fetching graph data';
      });
  }
});

export const { setUpdatedTrades } = homeSlice.actions;

export default homeSlice.reducer;
