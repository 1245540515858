import React, { useEffect, useState } from 'react';
import brandLogo from '../../assets/images/logo.png';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import eyeShow from '../../assets/Img/eye-show.svg';
import eyeHide from '../../assets/Img/eye-hide.svg';
import axios from 'axios';
import { message } from 'antd';
import AppConfig from '../../config/index';

const CreatePassword = (props) => {
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [isResetLinkExpired, setIsResetLinkExpired] = useState(false);

  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const resetToken = urlParams.get('token');

  message.config({
    duration: 10,
    maxCount: 3,
    rtl: true,
    prefixCls: 'my-message'
  });
  useEffect(() => {
    if (confirmPassword === '') {
      setConfirmPasswordError('');
    } else if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match.');
    } else if (password === confirmPassword) {
      setConfirmPasswordError('');
    }
  }, [password, confirmPassword]);

  function parseJwt(token) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error('Invalid token', error);
      return null;
    }
  }

  useEffect(() => {
    const decodeToken = () => {
      try {
        const decodedToken = parseJwt(resetToken);
        const storedExpiryTime = decodedToken.exp;
        if (storedExpiryTime) {
          // Convert expiry timestamp to milliseconds
          const expiryTimeMs = Number(storedExpiryTime) * 1000;
          // Get the current time in milliseconds
          const currentTimeMs = Date.now();
          // Calculate the difference in milliseconds
          const differenceMs = expiryTimeMs - currentTimeMs;
          if (differenceMs <= 0) {
            setIsResetLinkExpired(true);
            message.error(
              'The password change link has expired for security reasons',
              1
            );
          }
        }
      } catch (error) {
        console.log('Error decoding token.');
      }
    };
    decodeToken();
  }, [resetToken, navigate]);

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setErrorMessage('');
    setPassword(value);
    if (value === '') {
      setPasswordError('');
    } else if (value.length === 20) {
      setPasswordError('Password must be between 8 and 20 characters long.');
    } else if (
      !/[A-Z]/.test(value) ||
      !/\d/.test(value) ||
      !/[@$!%*?&]/.test(value) ||
      value.length < 8 ||
      /\s/.test(value)
    ) {
      setPasswordError(
        'Password must be at least 8 characters and include a mix of letters, numbers, and symbols'
      );
    } else {
      setPasswordError('');
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setErrorMessage('');
    setConfirmPassword(value);
    if (value === '') {
      setConfirmPasswordError('');
    } else if (value !== password) {
      setConfirmPasswordError('Passwords do not match.');
    } else {
      setConfirmPasswordError('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (
    //   !/[A-Z]/.test(password) ||
    //   !/\d/.test(password) ||
    //   !/[@$!%*?&]/.test(password) ||
    //   password.length < 8 ||
    //   /\s/.test(password)
    // ) {
    //   setErrorMessage(
    //     'Password must be at least 8 characters and include a mix of letters, numbers, and symbols'
    //   );
    if (!password) {
      message.error('Please enter Password', 1.5);
    } else if (!confirmPassword) {
      message.error('Please enter confirm Password', 1.5);
    } else if (passwordError) {
      return false;
    } else if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match.');
      // message.error('confirm Passwords does not match', 1.5);
    } else if (confirmPasswordError) {
      return false;
      // message.error('confirm Passwords does not match', 1.5);
    } else {
      try {
        await axios.post(`${AppConfig.apiUrl}/update_user_password`, {
          reset_token: resetToken,
          new_password: password,
          confirm_password: confirmPassword
        });
        setSuccessMessage('Password updated successfully');
        navigate('/ ');
      } catch (error) {
        setErrorMessage(
          'An error occurred while updating your password. Please try again later.'
        );
      }
    }
  };
  const goToLogin = () => {
    localStorage.clear();
    sessionStorage.removeItem('persist:root');
    window.location.replace('/');
  };

  return (
    <>
      {isResetLinkExpired ? (
        <div className="login-wrapper ">
          <div className="loginBlock">
            <div className="brandLogo">
              <img src={brandLogo} alt="Brand Logo" />
            </div>
            <div className="login-form">
              <p className="sucessResetPw">
                Sorry, The reset password link sent to your email has expired.
              </p>
              <div className="mt-3">
                <button
                  onClick={goToLogin}
                  type="submit"
                  className="btn btn-sm btn-primary w-100"
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="login-wrapper ">
          <div className="loginBlock">
            <div className="brandLogo">
              <img src={brandLogo} alt="Brand Logo" />
            </div>
            <form className="login-form" onSubmit={handleSubmit}>
              <div class="sign-title">Create New Password</div>
              <p className="mb-3">
                Your new password must be unique and different from previous
                password.
              </p>
              <div className="pb-3">
                <label className="form-label">New password</label>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <input
                    type={showNewPassword ? 'text' : 'password'}
                    name="newPassword"
                    value={password}
                    onChange={handlePasswordChange}
                    className="form-control"
                    maxLength={20}
                  />
                  <button
                    className="password-toggle-btn"
                    style={{
                      border: 'none',
                      width: '50px',
                      height: '35px',
                      cursor: 'pointer',
                      marginLeft: '-50px',
                      fontSize: '16px',
                      background: 'none'
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowNewPassword(!showNewPassword);
                    }}
                  >
                    <img
                      alt="eyeIcon"
                      src={showNewPassword ? eyeShow : eyeHide}
                    />
                  </button>
                </div>
                <div style={{ height: 20 }}>
                  {passwordError && <p className="errormsg">{passwordError}</p>}
                </div>
              </div>

              <div className="pb-3">
                <label className="form-label">Confirm password</label>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <input
                    type={showConfirmPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    className="form-control"
                    onPaste={(e) => e.preventDefault()}
                    maxLength={20}
                  />
                  <button
                    className="password-toggle-btn"
                    style={{
                      border: 'none',
                      width: '50px',
                      height: '35px',
                      cursor: 'pointer',
                      marginLeft: '-50px',
                      fontSize: '16px',
                      background: 'none'
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowConfirmPassword(!showConfirmPassword);
                    }}
                  >
                    <img
                      alt="eyeIcon"
                      src={showConfirmPassword ? eyeShow : eyeHide}
                    />
                  </button>
                </div>
                <div style={{ height: 20 }}>
                  {confirmPasswordError && (
                    <p className="errormsg">{confirmPasswordError}</p>
                  )}
                </div>
              </div>

              {successMessage && <p className="successmsg">{successMessage}</p>}
              {errorMessage && <p className="errormsg">{errorMessage}</p>}
              <div className="mt-3">
                <button type="submit" className="btn btn-sm btn-primary w-100">
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default CreatePassword;
