import React, { useState, useEffect } from 'react';
import brandLogo from '../../assets/images/logo.png';
import axios from 'axios';
import * as Yup from 'yup';
import AppConfig from '../../config';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [backendMessage, setBackendMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState({});
  const Helper = require('../../utils/helper');
  const token = localStorage.getItem('jwtToken');
  // Validation schema
  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required')
  });

  // Validate form manually

  useEffect(() => {
    Helper.isTokenExpired(token);
  }, []);
  const validateForm = async () => {
    try {
      await validationSchema.validate({ email }, { abortEarly: false });
      return true;
    } catch (validationErrors) {
      const formErrors = {};
      validationErrors.inner.forEach((error) => {
        formErrors[error.path] = error.message;
      });
      setErrors(formErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear previous errors
    setErrors({});
    setErrorMessage('');
    setBackendMessage('');

    const isValid = await validateForm();
    if (!isValid) return;

    try {
      setButtonDisabled(true);
      const response = await axios.post(`${AppConfig.apiUrl}/forget_password`, {
        email
      });
      setBackendMessage(response.data.message);
      setButtonDisabled(false);
    } catch (error) {
      const backendErrorMessage =
        error.response?.data?.message || 'An error occurred';

      setErrorMessage(backendErrorMessage);
      setButtonDisabled(false);
    }
  };

  return (
    <>
      <div className="login-wrapper ">
        <div className="loginBlock">
          <div className="brandLogo">
            <img src={brandLogo} alt="Brand Logo" />
          </div>
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="sign-title">Forgot Password?</div>
            <p className="mb-3">
              Enter the email address you used when you joined and we’ll send
              you instructions to reset your password.
            </p>
            <div>
              <label className="form-label">Email address</label>
              <input
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setButtonDisabled(false);
                  setErrorMessage('');
                  setBackendMessage('');
                }}
                type="email"
                className="form-control"
              />
              <div
                className="error-message"
                style={{ height: 15, marginTop: 2 }}
              >
                {errors.email ? errors.email : null}
              </div>
            </div>

            {backendMessage && <p className="successmsg">{backendMessage}</p>}
            {errorMessage && (
              <div className="error-message" style={{ color: 'red' }}>
                {errorMessage}
              </div>
            )}

            <div className="mt-3">
              <button
                type="submit"
                disabled={isButtonDisabled}
                className="btn btn-sm btn-primary w-100"
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
