function getEnvVariable(name: string): string {
  const value = process.env[name];
  if (!value) {
    throw new Error(`Environment variable ${name} is not defined`);
  }
  return value;
}

const API_URL =
  process.env.NODE_ENV === 'production'
    ? getEnvVariable('REACT_APP_PROD_API_URL')
    : getEnvVariable('REACT_APP_DEV_API_URL');

interface AppConfigType {
  apiUrl: string;
}

const AppConfig: AppConfigType = {
  apiUrl: API_URL || 'https://api.fractalalpha.com'
};

console.warn('App environment =>', process.env.NODE_ENV);

export default AppConfig;
